import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { ApiClient } from "@/api-config/api-client";
import {
  getCoreModelEndpoint,
  getWorkFlowBaseUrl,
} from "@/api-config/api-service";
import { updateProductListStatus } from "./product-management-util-service";
import { ProductType } from "./model/product-models";

async function fetchProductList(pageLimit = 10000, currPageNo = 1) {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  const workflowApi = new ApiClient({
    baseUrl: getWorkFlowBaseUrl(),
  });

  const [product, workflowProduct] = await Promise.all([
    api.get(
      `${API_ROUTE_CONFIGURATION.product}?filter.limit=${pageLimit}&filter.page=${currPageNo}`
    ),
    workflowApi.get(`${API_ROUTE_CONFIGURATION.product}`),
  ]).then((data) => data);
  return product?.data
    ? updateProductListStatus(product.data, workflowProduct)
    : [];
}

async function fetchProductGroup() {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  return api.get(`${API_ROUTE_CONFIGURATION.prodGroup}`);
}

async function updateProductInSummary(payload: ProductType) {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  return api.put(`${API_ROUTE_CONFIGURATION.product}/${payload.name}`, {
    body: JSON.stringify(payload),
  });
}

async function fetchExistingProduct(productName: string) {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  const workflowApi = new ApiClient({
    baseUrl: getWorkFlowBaseUrl(),
  });
  const [product, workflowProduct] = await Promise.all([
    api.get(`${API_ROUTE_CONFIGURATION.product}/${productName}`),
    workflowApi.get(`${API_ROUTE_CONFIGURATION.product}/${productName}`),
  ]).then((data) => data);
  const { data } = product;

  return updateProductListStatus(data, workflowProduct);
}

async function createProduct(payload: ProductType) {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  return api.post(`${API_ROUTE_CONFIGURATION["product"]}`, {
    body: JSON.stringify(payload),
  });
}

export {
  fetchProductList,
  fetchProductGroup,
  updateProductInSummary,
  fetchExistingProduct,
  createProduct,
};
