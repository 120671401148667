"use client";
import {
  Radio,
  RadioProps,
  RadioGroup,
  StackDirection,
  Stack,
  Box,
} from "@chakra-ui/react";
import "./RadioButtonGroup.scss";
import { useSelector } from "react-redux";
import { getEntitlement, getEnvIsDisable } from "@/store";
import {
  IFX_ACCT_PRODUCT_TYPE,
} from "@/utils/constants";
import { useContext } from "react";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import cx from "classnames";
import { checkReadOnlyEntitlement, isConsolePages } from "@/utils/common";

export type AppRadioProps<T> = {
  label: string;
  radioProps?: RadioProps;
  value: T;
  disabled?: boolean;
};

type Value<T> = T;

export type RadioButtonGroupProps<T> = {
  value: Value<T> | undefined;
  onChange: (val: Value<T>) => void;
  radioPropList: AppRadioProps<T>[];
  stackDirection?: StackDirection;
  spacing?: number | string;
  customClass?: string;
  isBoxedRadio?: boolean;
  onBlur?: (event: React.FocusEvent) => void;
  disabled?: boolean;
  error?: boolean;
};

export default function RadioButtonGroup<T>(props: RadioButtonGroupProps<T>) {
  const {
    value,
    onChange,
    error,
    radioPropList = [],
    stackDirection = "row",
    spacing = "8",
    customClass,
    isBoxedRadio = false,
    onBlur,
  } = props;

  const envIsDisabled = useSelector(getEnvIsDisable);
  const entitlement = useSelector(getEntitlement);
  const isConsolePage = isConsolePages();
  const { productDetails } = useContext<ProductConfigurationContextType>(
    productConfigurationContext
  );
  const prodType = IFX_ACCT_PRODUCT_TYPE[productDetails?.ifxAcctType!];
  const checkEntitlement = checkReadOnlyEntitlement(entitlement, prodType);

  const _handleChange = (value: string) => {
    if (isNaN(Number(value))) {
      return onChange(value as T);
    }
    return onChange(parseInt(value) as T);
  };

  const getValue = (val: T | undefined) => {
    return isNaN(Number(val)) ? val : parseInt(val as string);
  };

  return (
    <RadioGroup
      className={`${customClass}`}
      onChange={_handleChange}
      value={getValue(value) as any}
      isDisabled={props.disabled}
    >
      <Stack direction={stackDirection} spacing={spacing}>
        {radioPropList?.length
          ? radioPropList.map((radioProp, radioBoxIndex) => (
              <Box
                key={`${radioProp.value}${radioBoxIndex}`}
                className={cx({
                  "radio-button-box": true,
                  "radio-box": isBoxedRadio,
                  "redio-selected": value === radioProp.value,
                  error,
                })}
              >
                <Radio
                  {...radioProp.radioProps}
                  value={
                    radioProp?.value !== undefined
                      ? getValue(radioProp.value)
                      : ("" as any)
                  }
                  isDisabled={
                    (envIsDisabled && isConsolePage) ||
                    radioProp.disabled ||
                    checkEntitlement
                  }
                  className="radio-button"
                  onBlur={onBlur}
                >
                  {radioProp.label}
                </Radio>
              </Box>
            ))
          : ""}
      </Stack>
    </RadioGroup>
  );
}
