"use client";
import {
  DocLinksType,
  FlyoutAndTooltipType,
  ApiLibraryDetailType,
  ApiLibraryFlyoutType,
  TooltipDataType,
} from "@/models/global-models";
import { deepCopy } from "@finxact/finxact-shared-ui";

import * as prismic from "@prismicio/client";
import {
  AllDocumentTypes,
  ImageWithTooltipsSlice,
} from "../../prismicio-types";

export async function getDocCenterConfig(
  prismicClient: prismic.Client<AllDocumentTypes>
) {
  const data = await prismicClient.getByUID(
    "doc_center_config",
    "doc-center-config-item"
  );
  return data;
}

export async function getWalkthroughConfig(
  prismicClient: prismic.Client<AllDocumentTypes>,
  slug: string
) {
  const dataRaw = await prismicClient.getByUID("walkthrough_screen", slug);

  if (dataRaw) {
    const createTooltipsMaps = (
      slices: prismic.SliceZone<ImageWithTooltipsSlice>
    ) => {
      return slices.map((slice, index) => {
        if (
          slice.slice_type === "image_with_tooltips" &&
          slice.items.length > 0
        ) {
          const tooltipsMap = new Map<string, { tooltip: string }>(
            slice.items.map((item: any) => [
              item.tooltip_key,
              { tooltip: item.tooltip },
            ])
          );
          return {
            page: index,
            image: slice.primary.image.url,
            tooltipsMap,
          };
        }
        return {
          page: index,
          image: slice.primary.image.url,
          tooltipsMap: new Map<string, { tooltip: string }>(),
        };
      });
    };

    const tooltipsMapsArray = createTooltipsMaps(dataRaw.data.slices);

    const result = {
      walkthroughdetails: dataRaw,
      slides: tooltipsMapsArray,
    };

    return result;
  }
}

export async function getDefintion(
  prismicClient: prismic.Client<AllDocumentTypes>,
  ids: string
) {
  const dataRaw = await prismicClient.getByID(ids);
  const data = dataRaw.data as any;
  const definitionText = data?.definition[0]?.text;
  return definitionText;
}

export async function getAcademyHomeConfig(
  prismicClient: prismic.Client<AllDocumentTypes>
) {
  const data = await prismicClient.getByUID(
    "academy_config",
    "academy-home-config"
  );
  return data;
}
export async function getEnrollmentConfig(
  prismicClient: prismic.Client<AllDocumentTypes>
) {
  const data = await prismicClient.getAllByType("enrollment");
  return data;
}

export async function getCloverPaymentConfig(
  prismicClient: prismic.Client<AllDocumentTypes>
) {
  const data = await prismicClient.getAllByType("clover_payment");
  return data;
}

export async function getMenu(
  prismicClient: prismic.Client<AllDocumentTypes>,
  slug: any
) {
  const data = await prismicClient.getByUID("menus_custom_type", slug);
  const menuInfo = data?.data?.slices[0]?.items;
  return menuInfo;
}

export async function getAllDocuments(
  prismicClient: prismic.Client<AllDocumentTypes>,
  type: any
) {
  const data = await prismicClient.getAllByType(type);
  return data;
}

export async function getByUidApi(
  prismicClient: prismic.Client<AllDocumentTypes>,
  type: any,
  uid: any
) {
  const data = await prismicClient.getByUID(type, uid);
  return data;
}

async function getDocCenterTitle(
  prismicClient: prismic.Client<AllDocumentTypes>,
  type: "document" | "document_center",
  slug: any
) {
  const dataRaw = await prismicClient.getByUID(type, slug);
  const data = dataRaw.data as any;
  const title = data?.article_main_title?.[0]?.text;
  const timeToRead = data?.time_to_read || null;
  const caption = data?.flyout_subhead || null;
  const uid = dataRaw.uid;
  const docData = {
    title: title,
    time: timeToRead,
    uid: uid,
    type: type,
    caption: caption,
  };
  return docData;
}

async function getWalkthroughSliceCode(
  prismicClient: prismic.Client<AllDocumentTypes>,
  slug: string
) {
  const dataRaw = await prismicClient.getByUID("code_walkthrough", slug);
  if (dataRaw.data.language === "JSON") {
    const dataItem = dataRaw?.data as any;
    return dataItem?.code?.[0]?.text || null;
  }
  return null;
}

async function getAPIFlyoutDetails(
  prismicClient: prismic.Client<AllDocumentTypes>,
  slug: any
) {
  const dataRaw = await prismicClient.getByUID("api_library", slug);
  const title = dataRaw.data.api_title;
  const dataItem = dataRaw.data as any;
  const description = dataItem.api_object_short_description;
  var jsonData: string[] = [];
  dataRaw.data.slices?.map((slice: any) => {
    if (
      slice?.primary.language === "Json" &&
      slice?.primary.codeheading !== "ENDPOINTS"
    ) {
      jsonData.push(slice.primary.code?.[0]?.text);
    }
    if (slice?.slice_type === "CodeWalkthrough") {
      const data = getWalkthroughSliceCode(
        prismicClient,
        slice?.primary?.codeWalkthroughLink.uid
      ).then((response) => {
        return response;
      });
      Promise.all([data]).then((values) => {
        if (values?.length) {
          jsonData.push(values[0]);
        }
      });
    }
  });
  const apiData = {
    title: title,
    description: description,
    jsonData: jsonData,
    uid: slug,
  };
  return apiData;
}

export async function getAcademyPageConfig(
  prismicClient: prismic.Client<AllDocumentTypes>,
  page: "academy-catalog-config" | "academy-badge-cert-config"
) {
  const data = await prismicClient.getByUID("academy_page_config", page);
  return data;
}

export async function getAcademySharePage(
  prismicClient: prismic.Client<AllDocumentTypes>
) {
  const data = await prismicClient.getByUID(
    "academy_share_page",
    "academy-share-page"
  );
  return data;
}

export async function getAcademyUnauthConfig(
  prismicClient: prismic.Client<AllDocumentTypes>
) {
  const data = await prismicClient.getByUID(
    "academy_unauth_config",
    "academy-unauth"
  );
  return data;
}

export const defaultPrismicData: Required<FlyoutAndTooltipType> = {
  apiLinks: { apiDetails: [], apiCollection: null },
  docsLinks: [],
  docsVideos: [],
  tooltips: [],
  learningLinks: [],
  pageHeaderDetails: {},
  tooltipsMap: new Map<string, TooltipDataType>(),
};

export async function getFlyoutAndTooltipData(
  prismicClient: prismic.Client<AllDocumentTypes>,
  slug: any
) {
  try {
    const dataRaw = await prismicClient.getByUID("console_page_view", slug);
    if (dataRaw) {
      let {
        tooltips,
        docsLinks,
        docsVideos,
        apiLinks,
        learningLinks,
        pageHeaderDetails,
        tooltipsMap,
      } = deepCopy(defaultPrismicData);

      const dataItem = dataRaw.data as any;
      pageHeaderDetails = {
        heading: dataItem?.console_view_title,
        subHeading: dataItem?.page_description,
        sections: dataItem?.page_section_header_and_subheader,
      };

      let tooltipsIndex = dataRaw.data.slices1?.findIndex(
        (p) => p.slice_type == "tool_tip_all"
      );
      let tooltipsArray: any =
        tooltipsIndex !== -1 ? dataRaw.data.slices1[tooltipsIndex] : null;
      tooltips = tooltipsArray?.items || [];

      //New map implementation
      tooltipsMap = new Map(
        tooltipsArray?.items.map(
          ({
            tooltip_key,
            tooltip_question_text,
            tooltip_text_main,
          }: TooltipDataType) => [
            tooltip_key,
            { tooltip_question_text, tooltip_text_main },
          ]
        )
      );

      let apiLibrarySlugs = dataItem.api_library_content_items?.map(
        (api: any) => {
          return api?.item?.uid;
        }
      );
      const apiDetails = await Promise.all(
        apiLibrarySlugs.map(async (slug: string) => {
          return slug ? getAPIFlyoutDetails(prismicClient, slug) : null;
        })
      );
      apiLinks.apiDetails = apiDetails.filter((detail) => Boolean(detail));
      apiLinks.apiCollection = dataItem.postman_upload;

      let docLinksIndex = dataRaw.data.slices3?.findIndex(
        (p) => p.slice_type == "document_center_links"
      );
      let docLinksArray =
        docLinksIndex !== -1 ? dataRaw.data.slices3[docLinksIndex] : null;
      docLinksArray?.items.map((doc: any) => {
        const doclinkdata = getDocCenterTitle(
          prismicClient,
          doc.doccenterlinks.type,
          doc.doccenterlinks.uid
        );
        docsLinks?.push(doclinkdata as DocLinksType);
      });

      let docsVidsIndex = dataRaw.data.slices3?.findIndex(
        (p) => p.slice_type == "video_embeds"
      );
      let docsVidsArray =
        docsVidsIndex !== -1 ? dataRaw.data.slices3[docsVidsIndex] : null;
      docsVidsArray?.items.map((vid: any) => {
        if (vid?.embeddable?.html) docsVideos?.push(vid.embeddable.html);
      });

      const embeddedCourseData = extractEmbeddedCourseData(dataRaw.data);
      learningLinks = [...embeddedCourseData];
      return {
        tooltips,
        docsLinks,
        docsVideos,
        apiLinks,
        learningLinks,
        pageHeaderDetails,
        tooltipsMap,
      };
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

export const extractEmbeddedCourseData = (dataRaw: any) => {
  return (
    dataRaw.videos?.map((course: any) => {
      return {
        courseName: course.row_title,
        courseId: course.course_title,
        courseIdProd: course.course_title_prod,
        moduleId: course.module_title,
      };
    }) || []
  );
};

export async function getDocumentCenterNavigationItems(
  prismicClient: prismic.Client<AllDocumentTypes>
) {
  const items = await prismicClient.getAllByType("docnav");
  return items;
}

export async function getAllAPILibraryNavigationItems(
  prismicClient: prismic.Client<AllDocumentTypes>
) {
  const items = await prismicClient.getAllByType("api_nav");
  return items;
}

function getApiDetails(item: any, slug: string) {
  const title = item?.api_title as string;
  const description = item?.api_object_short_description as string;
  var jsonData = "";
  item?.slices?.map((slice: any) => {
    if (slice?.primary.language === "Json") {
      jsonData = jsonData + slice.primary.code?.[0]?.text;
    }
  });
  const apiData: ApiLibraryDetailType = {
    title: title,
    description: description,
    jsonData: jsonData,
    uid: slug,
  };
  return apiData;
}

export async function getProductSummaryApi(
  prismicClient: prismic.Client<AllDocumentTypes>,
  slug: string
) {
  const apiLinks: ApiLibraryFlyoutType = {
    apiDetails: [],
    apiCollection: null,
  };
  const dataRaw = await prismicClient.getByUID("product_summary_api", slug, {
    graphQuery: `{
    product_summary_api {
      postman_upload
      api_content_items {
        item {
          ... on api_library {
            api_title
            api_object_short_description
            slices {
              ... on code_snippet_header {
                variation {
                  ...on default {
                    primary {
                      code
                      language
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }`,
  });

  apiLinks && (apiLinks.apiCollection = dataRaw?.data?.postman_upload);
  dataRaw?.data?.api_content_items?.map((item: any) => {
    const apiData = getApiDetails(item?.item?.data, item?.item?.uid);
    apiLinks?.apiDetails?.push(apiData);
  });
  return apiLinks;
}

export async function getHelpfulResourcesConfig(
  prismicClient: prismic.Client<AllDocumentTypes>,
  slug: string
) {
  const resourcesData = await prismicClient.getByUID("helpful_resources", slug);
  const resource = resourcesData?.data?.slices?.[0];
  const resourceSection = resource
    ? {
        sectionTitle: resource?.primary?.resources_title,
        sectionSubTitle: resource.primary?.resources_subheader,
        resourcesLinks: resource.items,
      }
    : {};
  return resourceSection;
}

export async function getTestingReadData(
  prismicClient: prismic.Client<AllDocumentTypes>
) {
  const apiCallQuery = `...on product_summary_api{
    postman_upload
    api_content_items {
      api_name
      description
      item {
        ... on api_library {
          api_object_short_description
        }
      }
    }
  }`;
  const data = await prismicClient.getByType("product_summary_testing", {
    graphQuery: `{
      product_summary_testing {
        heading
        description
        items
        api_heading
        api_description
        retail_deposit {
          ${apiCallQuery}
        }
        commercial_deposit {
          ${apiCallQuery}
        }
        retail_loan {
          ${apiCallQuery}
        }
        commercial_loan {
          ${apiCallQuery}
        }
      }
  }`,
  });
  const details = data?.results?.[0]?.data;
  const testing = details
    ? {
        heading: details.heading,
        description: details.description,
        items: details.items,
      }
    : null;
  const apiCalls = details
    ? {
        heading: details.api_heading,
        description: details.api_description,
        retailDeposit: details.retail_deposit,
        commercialDeposit: details.commercial_deposit,
        retailLoan: details.retail_loan,
        commercialLoan: details.commercial_loan,
      }
    : null;
  return { testing: testing, apiCalls: apiCalls };
}

export async function getProductLaunchpadResources(
  prismicClient: prismic.Client<AllDocumentTypes>
) {
  const resources = `...on helpful_resources{
    slices {
      ... on resources_links_section {
        variation {
          ...on default {
            items{
              link_title
              link_type
              external_link
              prismic_link
              academy_course_link
            }
          }
        }
      }
    }
  }`;
  const data = await prismicClient.getByType("product_launchpad_resources", {
    graphQuery: `{
      product_launchpad_resources {
        heading
        sda {
          ${resources}
        }
        dda {
          ${resources}
        }
        cda {
          ${resources}
        }
        loc {
          ${resources}
        }
        ila {
          ${resources}
        }
      }
  }`,
  });
  const details = data?.results?.[0]?.data;
  return details;
}
