import { useIsPermitted } from "@/utils/common";
import { MultiTieredSideMenuDataModel } from "../MultiTieredSideMenu/MultiTieredSideMenuDataModel";
import { useSelector } from "react-redux";
import {
  DEFAULT_ALL_ORGANIZATION_ID,
  getEnvId,
  getOrgID,
  getOrganizations,
} from "@/store";
import { ROUTE_BASE_URL } from "@/route-config/route-path";

const ProtectedRoute = ({
  route,
  children,
}: {
  route: MultiTieredSideMenuDataModel;
  children: React.ReactNode;
}) => {
  const isPermitted = useIsPermitted();
  const organization = useSelector(getOrganizations);
  const orgID = useSelector(getOrgID);
  const envID = useSelector(getEnvId);
  const allOrgsGroup =
    orgID &&
    organization &&
    organization[orgID]?.id === DEFAULT_ALL_ORGANIZATION_ID;
  const noEnvHideRoutes = [
    ROUTE_BASE_URL.SWAGGER,
    ROUTE_BASE_URL.CORE_SERVICING,
  ];

  // Enforce permissions
  if (
    !allOrgsGroup &&
    route.coreOperation &&
    !isPermitted("core", route.coreOperation, route.inclusive)
  ) {
    return null;
  }
  if (
    !allOrgsGroup &&
    route.consoleOperation &&
    !isPermitted("cnsl", route.consoleOperation)
  ) {
    return null;
  }

  if (!envID && noEnvHideRoutes.includes(route.path)) {
    return null;
  }
  return children;
};

export default ProtectedRoute;
