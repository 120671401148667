import Image from "next/image";
import { finxactOutlineTime, finxactOutlineTimDisabled } from "public/assets";
import TimePicker from "react-time-picker";
import "./timeField.scss";
import { useSelector } from "react-redux";
import { getEntitlement, getEnvIsDisable } from "@/store";
import moment from "moment";
import {
  Box,
  Button,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  VStack,
  useDisclosure,
} from "@/components/ChakraUiManager";
import { useContext, useMemo } from "react";
import {
  checkReadOnlyEntitlement,
  isConsolePages,
  isValidDate,
  useUserEntitlement,
} from "@/utils/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { IFX_ACCT_PRODUCT_TYPE } from "@/utils/constants";
type ValuePiece = Date | string | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

const DEFAULT_TIME = "00:00:00";
const MAX_TIME = "23:59:59";

type TimeFieldProps = {
  id?: string;
  min?: string;
  max?: string;
  value?: Value;
  onChange: (value: string) => void;
  onBlur?: (event: React.FocusEvent) => void;
  disabled?: boolean;
  ariaLabel: string;
  placeholder?: string;
  containerClassNames?: string;
  placement?:
    | "bottom-start"
    | "bottom"
    | "bottom-end"
    | "auto-start"
    | "auto"
    | "auto-end"
    | "top-start"
    | "top"
    | "top-end"
    | "left-start"
    | "left"
    | "left-end"
    | "right-start"
    | "right"
    | "right-end";
};

const TimeField = (props: TimeFieldProps) => {
  const envIsDisabled = useSelector(getEnvIsDisable);
  const entitlement = useSelector(getEntitlement);
  const isConsolePage = isConsolePages();
  const { productDetails } = useContext<ProductConfigurationContextType>(
    productConfigurationContext
  );
  const prodType = IFX_ACCT_PRODUCT_TYPE[productDetails?.ifxAcctType!];
  const checkEntitlement = checkReadOnlyEntitlement(entitlement, prodType);
  const { isOpen, onToggle, onClose } = useDisclosure();

  const _handleTime = (_v: Value) => {
    if (typeof _v === "string" || _v instanceof String)
      props.onChange(_v as string);
  };
  const _handleInvalidInput = () => {
    props.onChange(DEFAULT_TIME);
  };
  const HOUR_OPTIONS = Array.from({ length: 24 }, (_, i) => i).map((hour) =>
    hour < 10 ? `0${hour}` : `${hour}`
  );
  const MIN_OPTIONS = Array.from({ length: 60 }, (_, i) => i).map((min) =>
    min < 10 ? `0${min}` : `${min}`
  );

  const actualValue = useMemo(() => {
    return props.value !== "Invalid Date" ? props.value : null;
  }, [props.value]);

  const handleSelect = (val: string, index: number) => {
    let valArr = (actualValue ? actualValue.toString() : DEFAULT_TIME).split(
      ":"
    );
    if (valArr) valArr[index] = val;
    props.onChange(valArr?.join(":") || "");
  };
  const getValue = (index: number) => {
    if (actualValue) {
      return actualValue.toString().split(":")[index];
    }
    return "";
  };

  return (
    <div className="time-field-wrapper">
      <Popover
        isOpen={!envIsDisabled && !checkEntitlement && isOpen}
        onClose={onClose}
        placement={props.placement ?? "bottom-start"}
      >
        <PopoverTrigger>
          <Box
            className={`time-picker-input-container ${props.containerClassNames}`}
            onClick={props.disabled ? () => {} : onToggle}
          >
            <TimePicker
              hourPlaceholder="HH"
              minutePlaceholder="MM"
              secondPlaceholder="SS"
              minTime={props.min || DEFAULT_TIME}
              maxTime={props.max || MAX_TIME}
              value={actualValue}
              onChange={_handleTime}
              disabled={
                (envIsDisabled && isConsolePage) ||
                checkEntitlement ||
                props.disabled
              }
              aria-label={props.ariaLabel}
              onBlur={props.onBlur}
              clockIcon={
                <Image
                  src={
                    props.disabled
                      ? finxactOutlineTimDisabled.src
                      : finxactOutlineTime.src
                  }
                  width={16}
                  height={16}
                  alt="clock-icon"
                />
              }
              maxDetail="second"
              closeClock={false}
              format="HH:mm:ss"
              clockAriaLabel="Clock icon"
              hourAriaLabel="Hour"
              minuteAriaLabel="Minute"
              nativeInputAriaLabel="Time"
              secondAriaLabel="Second"
              openClockOnFocus={false}
              shouldOpenClock={() => false}
              clearIcon={null}
              onInvalidChange={_handleInvalidInput}
            />
          </Box>
        </PopoverTrigger>
        <Portal>
          <PopoverContent width="fit-content">
            <PopoverArrow />
            <PopoverBody className="time-picker-dropdown">
              <HStack gap={4}>
                <VStack className="time-picker-list">
                  {HOUR_OPTIONS.map((hrs) => (
                    <Box
                      key={hrs}
                      p={4}
                      onClick={() => handleSelect(hrs, 0)}
                      className={
                        hrs === getValue(0)
                          ? "selected"
                          : "time-picker-container"
                      }
                    >
                      {hrs}
                    </Box>
                  ))}
                </VStack>
                <VStack className="time-picker-list">
                  {MIN_OPTIONS.map((min) => (
                    <Box
                      key={min}
                      p={4}
                      onClick={() => handleSelect(min, 1)}
                      className={
                        min === getValue(1)
                          ? "selected"
                          : "time-picker-container"
                      }
                    >
                      {min}
                    </Box>
                  ))}
                </VStack>
                <VStack className="time-picker-list">
                  {MIN_OPTIONS.map((sec) => (
                    <Box
                      key={sec}
                      p={4}
                      onClick={() => handleSelect(sec, 2)}
                      className={
                        sec === getValue(2)
                          ? "selected"
                          : "time-picker-container"
                      }
                    >
                      {sec}
                    </Box>
                  ))}
                </VStack>
              </HStack>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </div>
  );
};

export default TimeField;
