"use client";
import { Button } from "@chakra-ui/react";
import React, { useEffect, useCallback, useRef } from "react";
import { downloadIcon } from "public/assets";

import Image from "next/image";

type GenericPdfDownloaderType = {
  downloadFileName: string;
  rootElementId: string;
};

const GenericPdfDownloader = (props: GenericPdfDownloaderType) => {
  const isDownloading = useRef(false);

  const handleDownloadClick = useCallback(() => {
    if (isDownloading.current) {
      return; // If download is already in progress, do nothing
    }
    isDownloading.current = true;

    var opt = {
      margin: [5, 0],
      filename: props.downloadFileName,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { format: [250, 297], compress: true },
      enableLinks: true,
      pagebreak: { mode: ["avoid-all"] },
    };

    const exportToPDF = async () => {
      const html2pdfModule = await import("html2pdf.js");
      const html2pdf = html2pdfModule.default || html2pdfModule;

      const tempElement = document.getElementById(props.rootElementId);
      if (tempElement) {
        const element = tempElement.cloneNode(true) as HTMLElement;

        Array.from(
          element
            .getElementsByClassName("doc-center-body")[0]
            .getElementsByTagName("pre")
        ).forEach((codeBlock) => {
          codeBlock.style.height = "100%";
        });
        Array.from(element.getElementsByClassName("video-embed")).forEach(
          (codeBlock: any) => {
            codeBlock.hidden = false;
          }
        );

        const parentElement = Array.from(
          element.getElementsByClassName("doc-center-body")
        )[0].parentElement;

        Array.from(
          element.getElementsByClassName("action-button")
        )[0]?.remove();
        Array.from(element.getElementsByClassName("doc-share"))[0].remove();

        // Specific to tutorials page.
        element.getElementsByClassName("tutorials-filter")[0]?.remove();

        Array.from(element.getElementsByClassName("video-embed")).forEach(
          (codeBlock: any) => {
            codeBlock.hidden = false;
          }
        );

        html2pdf()
          .set(opt)
          .from(element)
          .save()
          .then(() => {
            isDownloading.current = false; // Reset download flag
            element.remove(); // remove the cloned element
          });
      }
    };

    exportToPDF();
  }, [props.downloadFileName, props.rootElementId]);

  useEffect(() => {
    const button = document.getElementById("export-btn");
    if (button) {
      button.addEventListener("click", handleDownloadClick);
      return () => {
        button.removeEventListener("click", handleDownloadClick);
      };
    }
  }, [handleDownloadClick]);

  return (
    <Button
      id="export-btn"
      leftIcon={
        <Image
          src={downloadIcon}
          alt={"download icon"}
          width={18}
          height={18}
        />
      }
      className="action-button"
      size="xl"
      variant="link"
    >
      Download
    </Button>
  );
};

export default GenericPdfDownloader;
