import {
  DASHBOARD_ACCORDION_STATUS,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  IFX_ACCT_PRODUCT_TYPE,
  IFX_PRODUCT_TYPE_VAL,
  REWARD_CONFIGURATION_DATA,
  SIDEBAR_STATUS,
  TIME_FORMAT,
} from "@/utils/constants";

import {
  StatusSideBarMainMenu,
  StatusSideBarMenuDefaultProps,
} from "@/models/global-models";

import {
  PRODUCT_STATUS,
  ProductSummaryFeatureDetailSteps,
  ProductSummaryFeatureFields,
  ProductSummaryType,
} from "./product-summary/product-summary-config";
import moment from "moment";
import {
  ProductGroupInfo,
  ProductType,
  ProductWorkflowModelType,
} from "./model/product-models";
import { formatTime } from "@/utils/common";
import {
  setItemInStorage,
  STORAGE_KEY,
  getItemFromStorage,
} from "@/utils/storage";
import { deepCopy } from "@finxact/finxact-shared-ui";

const defaultGroupInfo: ProductGroupInfo = {
  prodGroup: "",
  prodType: "",
  prodSubType: "",
};

function setGroupInfoInStorage(groupInfo: ProductGroupInfo) {
  if (groupInfo && Object.keys(groupInfo)?.length) {
    setItemInStorage(STORAGE_KEY["PRODUCT_GROUP_INFO"], groupInfo);
  }
}

function getGroupInfoFromStorage(): ProductGroupInfo {
  return getItemFromStorage(STORAGE_KEY["PRODUCT_GROUP_INFO"]);
}

function setQuickStartFlagInStorage(flag: boolean) {
  setItemInStorage(STORAGE_KEY["PRODUCT_QUICK_START_FLAG"], flag);
}

function getQuickStartFlag(): boolean {
  return getItemFromStorage(STORAGE_KEY["PRODUCT_QUICK_START_FLAG"]) ?? false;
}

function filterProductsWithWorkflowStatus(
  productList: ProductType[]
): ProductType[] {
  return productList?.length
    ? productList?.filter(
        (product) =>
          product?.status === DASHBOARD_ACCORDION_STATUS.completed ||
          product?.status === DASHBOARD_ACCORDION_STATUS.inProgress
      )
    : [];
}

function updateSideBarMenuStatus(
  mainMenuList: StatusSideBarMainMenu[],
  workflowProduct: ProductWorkflowModelType[]
) {
  if (
    workflowProduct &&
    workflowProduct?.length > 0 &&
    mainMenuList &&
    mainMenuList.length > 0
  ) {
    let updatedMainMenuList = mainMenuList;
    workflowProduct?.forEach((workflowItem) => {
      //got the product
      let indexOfMainMenuItem = updatedMainMenuList?.findIndex(
        (menu) => workflowItem.stage === menu.componentClass
      );
      let updatedMainMenu: StatusSideBarMainMenu;
      if (indexOfMainMenuItem > -1) {
        updatedMainMenu = updatedMainMenuList?.[indexOfMainMenuItem];
        const indexOfSubMenuList = updatedMainMenu?.subMenuList?.findIndex(
          (subMenu) => subMenu.stageStatus === workflowItem.status
        );
        // indexOfSubMenuList = -1 If component configuration has not started or no workflow exists
        if (indexOfSubMenuList !== undefined && indexOfSubMenuList > -1) {
          const updatedSubMenuList: StatusSideBarMenuDefaultProps[] =
            updatedMainMenu?.subMenuList?.map((subMenu, idx) => {
              if (idx <= indexOfSubMenuList) {
                return {
                  ...subMenu,
                  status: SIDEBAR_STATUS.completed,
                };
              } else {
                return subMenu;
              }
            }) || [];

          if (
            updatedSubMenuList?.every(
              (subMenu) => subMenu.status === SIDEBAR_STATUS.completed
            )
          ) {
            updatedMainMenu = {
              ...updatedMainMenu,
              status: SIDEBAR_STATUS.completed,
              subMenuList: updatedSubMenuList,
            };
            updatedMainMenuList[indexOfMainMenuItem] = updatedMainMenu;
          } else {
            updatedMainMenu = {
              ...updatedMainMenu,
              status: SIDEBAR_STATUS.in_progress,
              subMenuList: updatedSubMenuList,
            };
            updatedMainMenuList[indexOfMainMenuItem] = updatedMainMenu;
          }
        } else {
          if (!updatedMainMenu?.subMenuList) {
            updatedMainMenu = {
              ...updatedMainMenu,
              status:
                workflowItem.status === PRODUCT_STATUS.completed
                  ? SIDEBAR_STATUS.completed
                  : "NOT_STARTED",
            };
            updatedMainMenuList[indexOfMainMenuItem] = updatedMainMenu;
          }
        }
      }
    });
    return updatedMainMenuList;
  } else {
    //Setting product as completed incase workflow is not there
    const mainMenuUpdated: StatusSideBarMainMenu[] = mainMenuList.map(
      (menu) => {
        menu.status = SIDEBAR_STATUS.completed;
        menu.subMenuList?.forEach((subMenu) => {
          subMenu.status = SIDEBAR_STATUS.completed;
        });
        return menu;
      }
    );
    return mainMenuUpdated;
  }
}

const getFeatureStatus = (status: string) => {
  return status
    ? status === "completed"
      ? "completed"
      : status !== "not_started"
        ? "in_progress"
        : "not_started"
    : "";
};

const _autoFieldUpdate = (
  field: ProductSummaryFeatureFields,
  componentData: any
) => {
  return {
    ...field,
    value:
      componentData?.[field.key] !== ""
        ? field?.isPercentage && componentData?.[field.key]
          ? `${componentData?.[field.key]}%`
          : componentData?.[field.key]
        : undefined,
  };
};

const _booleanFieldUpdate = (
  field: ProductSummaryFeatureFields,
  componentData: any
) => {
  return {
    ...field,
    value: componentData?.[field.key]
      ? "Yes"
      : componentData?.[field.key] === false
        ? "No"
        : undefined,
  };
};

const _updateStatus = (idx: number, currentStageIndex: number) => {
  return idx <= currentStageIndex
    ? PRODUCT_STATUS.completed
    : PRODUCT_STATUS.not_started;
};

//Fee component
const updateFeeConfig = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  const newFieldsData = generateStructureForFee(
    updatedFeatureData.fields,
    componentData.feeItems,
    "Fee item"
  );
  return {
    ...updatedFeatureData,
    manuallyAddedFields: newFieldsData,
    status: _updateStatus(idx, currentStageIndex),
  };
};

const updateServiceChargeDetails = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  let updatedFields = updatedFeatureData.fields;
  updatedFields = updatedFields.map((field: any) => {
    switch (field.key) {
      case "perServChar": {
        return {
          ...field,
          value:
            componentData.svcChrgFreq ||
            componentData.feeMin !== undefined ||
            componentData.feeMax !== undefined
              ? "Yes"
              : undefined,
        };
      }
      case "svcChrgFreq": {
        return {
          ...field,
          value: componentData.svcChrgFreq
            ? componentData.svcChrgFreq
            : componentData.svcChrgFreqMatrix || undefined,
        };
      }
      case "minMax": {
        return {
          ...field,
          value: `$Min: ${componentData.feeMin} $Max: ${componentData.feeMax}`,
        };
      }
      default: {
        return _autoFieldUpdate(field, componentData);
      }
    }
  });
  return {
    ...updatedFeatureData,
    fields: updatedFields,
    status: _updateStatus(idx, currentStageIndex),
  };
};

const updateEarningAnalysis = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  return {
    ...updatedFeatureData,
    fields: updatedFeatureData.fields.map((field) => {
      switch (field.key) {
        default: {
          return _autoFieldUpdate(field, componentData);
        }
      }
    }),
    status: _updateStatus(idx, currentStageIndex),
  };
};

//Interest Component

const updateFoundationalInterestDetails = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  let updatedFeilds = updatedFeatureData.fields.map((field) => {
    switch (field.key) {
      default: {
        return _autoFieldUpdate(field, componentData);
      }
    }
  });
  return {
    ...updatedFeatureData,
    fields: updatedFeilds,
    status: _updateStatus(idx, currentStageIndex),
  };
};

const updateIndexRateFoundationalDetails = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  let updatedFields = updatedFeatureData.fields.map((field) => {
    switch (field.key) {
      case "indexedRate": {
        return {
          ...field,
          value: componentData.index
            ? "Yes"
            : componentData.nomRate
              ? "No"
              : undefined,
        };
      }
      case "nomRate": {
        return {
          ...field,
          value: componentData.nomRate || undefined,
        };
      }
      default: {
        return {
          ...field,
          value: componentData.index?.[field.key] || undefined,
        };
      }
    }
  });
  return {
    ...updatedFeatureData,
    fields: updatedFields.filter(Boolean),
    status: _updateStatus(idx, currentStageIndex),
  };
};

const updateIndexRateAdjustmentRanges = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  let updatedFields = updatedFeatureData.fields.map((field) => {
    switch (field.key) {
      case "maxRate":
        return {
          ...field,
          value: `Min%: ${componentData?.index?.minRate ? `${componentData.index?.minRate}%` : undefined} Max%: ${componentData?.index?.maxRate ? `${componentData.index?.maxRate}%` : undefined}`,
        };
      default:
        return _autoFieldUpdate(field, componentData.index);
    }
  });
  return {
    ...updatedFeatureData,
    fields: updatedFields,
    status: _updateStatus(idx, currentStageIndex),
  };
};

const updateIndexRateOffsetRounding = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  let updatedFields = updatedFeatureData.fields.map((field) => {
    switch (field.key) {
      case "rateOffset": {
        return {
          ...field,
          value:
            componentData.index?.offsetMatrix || componentData.index?.offSet
              ? "Yes"
              : undefined,
        };
      }
      case "offsetMatrix":
        return {
          ...field,
          value:
            componentData.index?.offsetMatrix ||
            componentData.index?.offSet ||
            undefined,
        };
      default: {
        return {
          ...field,
          value:
            componentData.index?.[field.key] !== ""
              ? componentData.index?.[field.key] || undefined
              : undefined,
        };
      }
    }
  });
  return {
    ...updatedFeatureData,
    fields: updatedFields.filter(Boolean),
    status: _updateStatus(idx, currentStageIndex),
  };
};

const updateIndexRateReviewFrequency = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  let updatedFields = updatedFeatureData.fields.map((field) => {
    switch (field.key) {
      case "isReviewDly": {
        return _booleanFieldUpdate(field, componentData.index);
      }
      default: {
        return _autoFieldUpdate(field, componentData.index);
      }
    }
  });
  return {
    ...updatedFeatureData,
    fields: updatedFields.filter(Boolean),
    status: _updateStatus(idx, currentStageIndex),
  };
};

const updatePromotionalRateFoundationDetails = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  let updatedFields = updatedFeatureData.fields.map((field) => {
    switch (field.key) {
      case "promotionalRate": {
        return {
          ...field,
          value: componentData.promoDtl
            ? "Yes"
            : componentData.nomRate
              ? "No"
              : undefined,
        };
      }
      default: {
        return _autoFieldUpdate(field, componentData.promoDtl);
      }
    }
  });
  return {
    ...updatedFeatureData,
    fields: updatedFields.filter(Boolean),
    status: _updateStatus(idx, currentStageIndex),
  };
};

const updatePromoRateAdjustmentsRanges = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  let updatedFields = updatedFeatureData.fields.map((field) => {
    switch (field.key) {
      case "maxRate":
        return {
          ...field,
          value: `Min%: ${componentData?.promoDtl?.minRate ? `${componentData.promoDtl?.minRate}%` : undefined} Max%: ${componentData?.promoDtl?.maxRate ? `${componentData.promoDtl?.maxRate}%` : undefined}`,
        };
      default: {
        return _autoFieldUpdate(field, componentData.promoDtl);
      }
    }
  });
  return {
    ...updatedFeatureData,
    fields: updatedFields,
    status: _updateStatus(idx, currentStageIndex),
  };
};

const updatePromoRateOffsetsRounding = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  let updatedFields = updatedFeatureData.fields.map((field) => {
    switch (field.key) {
      case "rateOffset": {
        return {
          ...field,
          value:
            componentData.promoDtl?.promoTerm ||
            componentData.promoDtl?.offsetMatrix ||
            componentData.promoDtl?.offSet
              ? "Yes"
              : undefined,
        };
      }
      case "offsetMatrix":
        return {
          ...field,
          value:
            componentData.promoDtl?.offsetMatrix ||
            componentData.promoDtl?.offSet ||
            undefined,
        };
      default: {
        return _autoFieldUpdate(field, componentData.promoDtl);
      }
    }
  });
  return {
    ...updatedFeatureData,
    fields: updatedFields.filter(Boolean),
    status: _updateStatus(idx, currentStageIndex),
  };
};

const updatePromoPostingFrequencyRounding = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  let updatedFields = updatedFeatureData?.fields?.map((field) => {
    switch (field?.key) {
      case "isPostNetInt": {
        return _booleanFieldUpdate(field, componentData);
      }
      case "svcChrgFreq": {
        return {
          ...field,
          value: componentData.svcChrgFreq
            ? componentData.svcChrgFreq
            : componentData.svcChrgFreqMatrix || undefined,
        };
      }
      case "postFreq": {
        return {
          ...field,
          value: componentData.postFreq
            ? componentData.postFreq
            : componentData.postFreqMatrix || undefined,
        };
      }
      default: {
        return _autoFieldUpdate(field, componentData);
      }
    }
  });
  return {
    ...updatedFeatureData,
    fields: updatedFields.filter(Boolean),
    status: _updateStatus(idx, currentStageIndex),
  };
};

const updateInterestAccrualDetails = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  let updatedFields = updatedFeatureData.fields.map((field) => {
    switch (field.key) {
      case "isCompoundDly": {
        return _booleanFieldUpdate(field, componentData);
      }
      default: {
        return _autoFieldUpdate(field, componentData);
      }
    }
  });
  return {
    ...updatedFeatureData,
    fields: updatedFields.filter(Boolean),
    status: _updateStatus(idx, currentStageIndex),
  };
};

//Reward Deposit

const getInitialRadioButtonValue = (
  goalTerm: string,
  payOut: { programType: string }[] | null,
  trnRewardProgram: any
) => {
  if (goalTerm) {
    return REWARD_CONFIGURATION_DATA.savingsGoalReward;
  }

  if (payOut && payOut.length > 0) {
    switch (true) {
      case payOut[0].programType ===
        "Adjust interest rate at accrual if qualifying":
        return REWARD_CONFIGURATION_DATA.relationshipReward;
      case payOut[0].programType === "Add bonus rate at rollover":
        return REWARD_CONFIGURATION_DATA.rolloverReward;
      default:
        return "";
    }
  } else {
    if (trnRewardProgram) {
      if (Object.values(trnRewardProgram).some((field) => Boolean(field))) {
        return REWARD_CONFIGURATION_DATA.transactionReward;
      }
    }
    return "";
  }
};
const updateRewardConfigurationOption = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  const config = getInitialRadioButtonValue(
    componentData.goalTerm,
    componentData.payoutOpt,
    componentData.trnRewardProgram
  );
  let updatedFields = updatedFeatureData.fields.map((field) => {
    switch (field.key) {
      case "configuration": {
        return (
          config !== "" && {
            ...field,
            value: config,
          }
        );
      }
      case "programType": {
        return (
          componentData.payoutOpt !== null &&
          componentData.payoutOpt.length > 0 && {
            ...field,
            value: componentData.payoutOpt
              .map((option: any) => option.programType)
              .join(", "),
          }
        );
      }
      case "program": {
        return (
          componentData.relationshipProgram && {
            ...field,
            value: componentData.relationshipProgram?.program,
          }
        );
      }
      case "subGroups": {
        return (
          componentData.relationshipProgram && {
            ...field,
            value: componentData.relationshipProgram?.subGroups.join(", "),
          }
        );
      }
      case "payoutTrnCode": {
        return {
          ...field,
          value: componentData?.trnRewardProgram?.payoutTrnCode || undefined,
        };
      }
      case "payoutFreq": {
        return {
          ...field,
          value: componentData?.trnRewardProgram?.payoutFreq || undefined,
        };
      }
      default: {
        return _autoFieldUpdate(field, componentData);
      }
    }
  });
  return {
    ...updatedFeatureData,
    fields: updatedFields.filter(Boolean),
    status: _updateStatus(idx, currentStageIndex),
  };
};

//limit deposit
const updateAccoutnBalanceConfiguration = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  let updatedFields = updatedFeatureData.fields.map((field) => {
    switch (field.key) {
      case "isMinBalRequired": {
        return {
          ...field,
          value:
            componentData?.minBal === undefined
              ? undefined
              : componentData?.minBal !== undefined
                ? "Yes"
                : "No",
        };
      }
      default: {
        return _autoFieldUpdate(field, componentData);
      }
    }
  });
  return {
    ...updatedFeatureData,
    fields: updatedFields.filter(Boolean),
    status: _updateStatus(idx, currentStageIndex),
  };
};

const updateRestrictionConfiguration = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  let updatedFields = updatedFeatureData.fields.map((field) => {
    switch (field.key) {
      case "restrictCrFundExp":
      case "restrictCr":
      case "restrictDr": {
        return _booleanFieldUpdate(field, componentData);
      }
      default: {
        return _autoFieldUpdate(field, componentData);
      }
    }
  });
  return {
    ...updatedFeatureData,
    fields: updatedFields.filter(Boolean),
    status: _updateStatus(idx, currentStageIndex),
  };
};

const updateSingleTransactionLimitConfig = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  componentData.perTrnLimits;
  const newFieldsData = generateStructureForMultipleIteration(
    updatedFeatureData.fields,
    componentData.perTrnLimits,
    "Single transaction limit"
  );
  return {
    ...updatedFeatureData,
    manuallyAddedFields: newFieldsData,
    status: _updateStatus(idx, currentStageIndex),
  };
};

const updateAccumulatedTransactionLimitConfiguration = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  componentData.accumTrnLimits;
  const newFieldsData = generateStructureForMultipleIteration(
    updatedFeatureData.fields,
    componentData.accumTrnLimits,
    "Accumulated transaction limit"
  );
  return {
    ...updatedFeatureData,
    manuallyAddedFields: newFieldsData,
    status: _updateStatus(idx, currentStageIndex),
  };
};

//Repayment Loan
const updatePrinciplaInterestConfig = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  let updatedFields = updatedFeatureData.fields.map((field) => {
    switch (field.key) {
      default: {
        return _autoFieldUpdate(field, componentData);
      }
    }
  });
  return {
    ...updatedFeatureData,
    fields: updatedFields.filter(Boolean),
    status: _updateStatus(idx, currentStageIndex),
  };
};

const updatePaymentConfig = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  let updatedFields = updatedFeatureData.fields.map((field) => {
    switch (field.key) {
      case "tolerancePct": {
        return {
          ...field,
          value:
            componentData.tolerancePct !== undefined
              ? `Payment tolerance percentage: ${componentData.tolerancePct}%`
              : componentData.toleranceAmt !== undefined
                ? `Payment tolerance amount: $${componentData.toleranceAmt}`
                : undefined,
        };
      }
      case "isRevolving": {
        return _booleanFieldUpdate(field, componentData);
      }
      default: {
        return _autoFieldUpdate(field, componentData);
      }
    }
  });
  return {
    ...updatedFeatureData,
    fields: updatedFields.filter(Boolean),
    status: _updateStatus(idx, currentStageIndex),
  };
};

const updatePaymentDirectionAndCharges = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  let updatedFields = updatedFeatureData.fields.map((field) => {
    switch (field.key) {
      case "priorityPaymentApplicationMethod": {
        return {
          ...field,
          value:
            componentData.pmtApplMthd === undefined
              ? undefined
              : Object.keys(componentData.pmtApplMthd).includes("Priority")
                ? "Yes"
                : "No",
        };
      }
      case "priorityDirection": {
        return {
          ...field,
          value: componentData.pmtApplMthd?.Priority?.direction,
        };
      }
      case "priorityDueItemOrder": {
        return {
          ...field,
          value: componentData.pmtApplMthd?.Priority?.dueItemOrder?.join(", "),
        };
      }
      case "pastPaymentApplicationMethod": {
        return {
          ...field,
          value:
            componentData.pmtApplMthd === undefined
              ? undefined
              : Object.keys(componentData.pmtApplMthd).includes("Past")
                ? "Yes"
                : "No",
        };
      }
      case "pastDirection": {
        return {
          ...field,
          value: componentData.pmtApplMthd?.Past?.direction,
        };
      }
      case "pastDueItemOrder": {
        return {
          ...field,
          value: componentData.pmtApplMthd?.Past?.dueItemOrder?.join(", "),
        };
      }
      case "currentPaymentApplicationMethod": {
        return {
          ...field,
          value:
            componentData.pmtApplMthd === undefined
              ? undefined
              : Object.keys(componentData.pmtApplMthd).includes("Current")
                ? "Yes"
                : "No",
        };
      }
      case "currentDirection": {
        return {
          ...field,
          value: componentData.pmtApplMthd?.Current?.direction,
        };
      }
      case "currentDueItemOrder": {
        return {
          ...field,
          value: componentData.pmtApplMthd?.Current?.dueItemOrder?.join(", "),
        };
      }
      default: {
        return _autoFieldUpdate(field, componentData);
      }
    }
  });
  return {
    ...updatedFeatureData,
    fields: updatedFields.filter(Boolean),
    status: _updateStatus(idx, currentStageIndex),
  };
};

const updatePrePaymentConfigurations = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  let updatedFields = updatedFeatureData.fields.map((field) => {
    switch (field.key) {
      case "prePmtMinAmt":
        return {
          ...field,
          value:
            componentData[field.key] !== undefined
              ? `$${componentData[field.key]}`
              : undefined,
        };
      case "prePmtMinPct":
        return {
          ...field,
          value:
            componentData[field.key] !== undefined
              ? `${componentData[field.key]}%`
              : undefined,
        };
      default: {
        return _autoFieldUpdate(field, componentData);
      }
    }
  });
  return {
    ...updatedFeatureData,
    fields: updatedFields.filter(Boolean),
    status: _updateStatus(idx, currentStageIndex),
  };
};

const updatePastDueConfigTracking = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  const newFieldsData = generateStructureForPastDueConfig(
    updatedFeatureData.fields,
    componentData.dueItems,
    "Due item"
  );
  return {
    ...updatedFeatureData,
    manuallyAddedFields: newFieldsData,
    status: _updateStatus(idx, currentStageIndex),
  };
};

const updateAdvancedComponentOptions = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  let updatedFields = updatedFeatureData.fields.map((field) => {
    switch (field.key) {
      default: {
        return _booleanFieldUpdate(field, componentData);
      }
    }
  });
  return {
    ...updatedFeatureData,
    fields: updatedFields.filter(Boolean),
    status: _updateStatus(idx, currentStageIndex),
  };
};

const updateSecuritySpecification = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  let updatedFields = updatedFeatureData.fields.map((field) => {
    switch (field.key) {
      default: {
        return _autoFieldUpdate(field, componentData);
      }
    }
  });
  return {
    ...updatedFeatureData,
    fields: updatedFields.filter(Boolean),
    status: _updateStatus(idx, currentStageIndex),
  };
};

//charge off component deposite
const updateChargeOffTimingThreshold = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  let updatedFields = updatedFeatureData.fields.map((field) => {
    switch (field.key) {
      default: {
        return _autoFieldUpdate(field, componentData);
      }
    }
  });
  return {
    ...updatedFeatureData,
    fields: updatedFields.filter(Boolean),
    status: _updateStatus(idx, currentStageIndex),
  };
};

const updateChargeTransactionCodeSpecifications = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  let updatedFields = updatedFeatureData.fields.map((field) => {
    switch (field.key) {
      case "transactionCodeValue": {
        return {
          ...field,
          value: componentData?.trnCode
            ? "Charge-off transaction code"
            : componentData?.trnCodeMatrix
              ? "Charge-off transaction code matrix"
              : undefined,
        };
      }
      default: {
        return _autoFieldUpdate(field, componentData);
      }
    }
  });
  return {
    ...updatedFeatureData,
    fields: updatedFields.filter(Boolean),
    status: _updateStatus(idx, currentStageIndex),
  };
};

//Transaction component
const updateTransactionCodeSpecifications = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  let updatedFields = updatedFeatureData.fields.map((field) => {
    switch (field.key) {
      case "inclRoundUpTrnCodes": {
        return {
          ...field,
          value: componentData.inclRoundUpTrnCodes?.join(", "),
        };
      }
      default: {
        return _autoFieldUpdate(field, componentData);
      }
    }
  });
  return {
    ...updatedFeatureData,
    fields: updatedFields.filter(Boolean),
    status: _updateStatus(idx, currentStageIndex),
  };
};

const updateMaturityConfiguration = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  let updatedFields = updatedFeatureData.fields.map((field) => {
    switch (field.key) {
      default: {
        return _autoFieldUpdate(field, componentData);
      }
    }
  });
  return {
    ...updatedFeatureData,
    fields: updatedFields.filter(Boolean),
    status: _updateStatus(idx, currentStageIndex),
  };
};

const updateRollOverProdIntrestRateConfig = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  let updatedFields = updatedFeatureData.fields.map((field) => {
    switch (field.key) {
      default: {
        return _autoFieldUpdate(field, componentData);
      }
    }
  });
  return {
    ...updatedFeatureData,
    fields: updatedFields.filter(Boolean),
    status: _updateStatus(idx, currentStageIndex),
  };
};

const updateGracePeriodExtensions = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  let updatedFields = updatedFeatureData.fields.map((field) => {
    switch (field.key) {
      default: {
        return _autoFieldUpdate(field, componentData);
      }
    }
  });
  return {
    ...updatedFeatureData,
    fields: updatedFields.filter(Boolean),
    status: _updateStatus(idx, currentStageIndex),
  };
};

const updatePenaltiesAdjustments = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  let updatedFields = updatedFeatureData.fields.map((field) => {
    switch (field.key) {
      case "skipIntPost": {
        return _booleanFieldUpdate(field, componentData);
      }
      default: {
        return _autoFieldUpdate(field, componentData);
      }
    }
  });
  return {
    ...updatedFeatureData,
    fields: updatedFields.filter(Boolean),
    status: _updateStatus(idx, currentStageIndex),
  };
};

//nsf
const updateComponentDataDefaultFunction = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  let updatedFields = updatedFeatureData.fields.map((field) => {
    switch (field.key) {
      case "negLimitMatrix": {
        return {
          ...field,
          value:
            componentData.negLimitMatrix || componentData.negLimit
              ? componentData.negLimit
                ? "New fixed overdraft limit"
                : componentData.negLimitMatrix
              : undefined,
        };
      }
      case "isNegLimitOptIn":
      case "isAutoAuth":
      case "isWaiveNsfFee":
      case "isNegLimitNsfFee":
        return _booleanFieldUpdate(field, componentData);
      default:
        return _autoFieldUpdate(field, componentData);
    }
  });
  return {
    ...updatedFeatureData,
    fields: updatedFields.filter(Boolean),
    status: _updateStatus(idx, currentStageIndex),
  };
};

const updateNsfTransactionCodeSpecification = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  let updatedFields = updatedFeatureData.fields.map((field) => {
    switch (field.key) {
      default: {
        return {
          ...field,
          value: componentData?.[field.key]?.join(", "),
        };
      }
    }
  });
  return {
    ...updatedFeatureData,
    fields: updatedFields.filter(Boolean),
    status: _updateStatus(idx, currentStageIndex),
  };
};

const udpdateRulesComponentGroups = (
  updatedFeatureData: ProductSummaryFeatureDetailSteps,
  componentData: any,
  currentStageIndex: number,
  idx: number
) => {
  const newFieldsData = generateStructureForRules(
    updatedFeatureData.fields,
    componentData.ruleGroupList,
    "Rule groups"
  );
  return {
    ...updatedFeatureData,
    manuallyAddedFields: newFieldsData,
    status: _updateStatus(idx, currentStageIndex),
  };
};

const constructProductSummaryFeatureDetails = (
  featureData: ProductSummaryFeatureDetailSteps[],
  componentData: any,
  currentStageIndex: number
) => {
  return featureData.map((data, idx) => {
    let updatedFeatureData = { ...data };
    switch (data.stageKey) {
      //Fee compoenent
      case "feeConfig": {
        return updateFeeConfig(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }
      case "serviceChargeDetails": {
        return updateServiceChargeDetails(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }
      case "earningAnalysis": {
        return updateEarningAnalysis(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }

      //interest
      case "foundationalInterestDetails": {
        return updateFoundationalInterestDetails(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }

      case "indexRateFoundationalDetails": {
        return updateIndexRateFoundationalDetails(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }

      case "indexRateAdjustmentRanges": {
        return updateIndexRateAdjustmentRanges(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }

      case "indexRateOffsetRounding": {
        return updateIndexRateOffsetRounding(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }

      case "indexRateReviewFrequency": {
        return updateIndexRateReviewFrequency(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }
      case "promotionalRateFoundationDetails": {
        return updatePromotionalRateFoundationDetails(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }
      case "promoRateAdjustmentsRanges": {
        return updatePromoRateAdjustmentsRanges(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }

      case "promoRateOffsetsRounding": {
        return updatePromoRateOffsetsRounding(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }
      case "promoPostingFrequencyRounding": {
        return updatePromoPostingFrequencyRounding(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }
      case "interestAccrualDetails": {
        return updateInterestAccrualDetails(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }

      // Reward Deposit
      case "rewardConfiguration": {
        return updateRewardConfigurationOption(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }

      //Limit Deposit
      case "accoutnBalanceConfiguration": {
        //should change implementation based on received array
        return updateAccoutnBalanceConfiguration(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }

      case "restrictionConfiguration": {
        return updateRestrictionConfiguration(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }

      case "singleTransactionLimitConfig": {
        return updateSingleTransactionLimitConfig(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }

      case "accumulatedTransactionLimitConfiguration": {
        return updateAccumulatedTransactionLimitConfiguration(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }

      //Repayment Loan
      case "principlaInterestConfig": {
        return updatePrinciplaInterestConfig(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }
      case "paymentConfig": {
        return updatePaymentConfig(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }
      case "paymentDirectionAndCharges": {
        return updatePaymentDirectionAndCharges(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }
      case "prePaymentConfigurations": {
        return updatePrePaymentConfigurations(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }
      case "pastDueConfigTracking": {
        return updatePastDueConfigTracking(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }
      case "advancedComponentOptions": {
        return updateAdvancedComponentOptions(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }

      //collateral deposit
      case "securitySpecification": {
        if (
          (componentData?.secPct !== null && componentData?.secPct > -1) ||
          !componentData?.hasOwnProperty("secPct")
        ) {
          if (
            !componentData?.hasOwnProperty("secPct") &&
            componentData?.secLvl === "Unsecured"
          ) {
            componentData.secPct = 0;
          }
          return updateSecuritySpecification(
            updatedFeatureData,
            componentData,
            currentStageIndex,
            idx
          );
        } else {
          return {
            ...updatedFeatureData,
            status: _updateStatus(idx, currentStageIndex),
          };
        }
      }

      //charge off component deposit
      case "chargeOffTimingThreshold": {
        return updateChargeOffTimingThreshold(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }

      case "chargeTransactionCodeSpecifications": {
        return updateChargeTransactionCodeSpecifications(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }

      //Transaction Compoenent
      case "transactionCodeSpecifications": {
        return updateTransactionCodeSpecifications(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }

      //term deposit
      case "maturityConfiguration": {
        return updateMaturityConfiguration(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }
      case "rollOverProdIntrestRateConfig": {
        return updateRollOverProdIntrestRateConfig(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }
      case "gracePeriodExtensions": {
        return updateGracePeriodExtensions(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }
      case "penaltiesAdjustments": {
        return updatePenaltiesAdjustments(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }
      // nsf
      case "negativeLimitDetails": {
        return updateComponentDataDefaultFunction(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }
      case "nsfTransactionCodeSpecifications": {
        return updateNsfTransactionCodeSpecification(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }
      case "rules": {
        return udpdateRulesComponentGroups(
          updatedFeatureData,
          componentData,
          currentStageIndex,
          idx
        );
      }
      default: {
        return data;
      }
    }
  });
};

export const mapSelectedAttributeData = (
  productDetails: any,
  attribute: ProductSummaryType
) => {
  const updatedSteps = attribute?.steps?.map((step) => {
    if (productDetails?.assetId) {
      let updatedFields = step.fields.map((field) => {
        switch (field.key) {
          case "isFedExempt":
          case "isStateExempt":
          case "isRegD":
          case "isNraExempt":
          case "isCrBureau":
          case "isBumpEnabled":
          case "isRateGuaranteed":
          case "maxLtvPctButton":
          case "regDProdTfrbutton": {
            return _booleanFieldUpdate(field, productDetails);
          }
          case "maxLvtPercentageButton": {
            return {
              ...field,
              value: productDetails?.maxLtvPct ? "Yes" : undefined,
            };
          }
          //avlEndDtm
          case "availableEndDate": {
            return {
              ...field,
              value: productDetails?.avlEndDtm
                ? moment(productDetails?.avlEndDtm)
                    .utc()
                    .format(DATE_FORMAT.shortDate)
                : undefined,
            };
          }
          case "endTime": {
            return {
              ...field,
              value: productDetails?.avlEndDtm
                ? moment(productDetails?.avlEndDtm)
                    .utc()
                    .format(TIME_FORMAT.shortTime)
                : undefined,
            };
          }
          case "avlStartDtm": {
            return productDetails?.avlStartDtm
              ? {
                  ...field,
                  value: moment(productDetails.avlStartDtm)
                    .utc()
                    .format(DATE_TIME_FORMAT.shortDateTime),
                }
              : productDetails?.avlStartDtmMatrix
                ? {
                    ...field,
                    value: productDetails.avlStartDtmMatrix,
                  }
                : productDetails?.stmtStartDtmOpt && {
                    ...field,
                    value: productDetails?.stmtStartDtmOpt,
                  };
          }
          case "glSetMatrixName": {
            return {
              ...field,
              value: productDetails?.glSetMatrixName
                ? productDetails?.glSetMatrixName
                : undefined,
            };
          }
          case "interismStmtButton": {
            return {
              ...field,
              value: productDetails?.interimStmtFreq ? "Yes" : undefined,
            };
          }
          // Loan Delinquency Data
          case "cutoffDur": {
            return {
              ...field,
              value:
                productDetails?.loanDelinquencyData?.nonAccr?.cutoffDur ??
                undefined,
            };
          }
          case "minDue": {
            return {
              ...field,
              value:
                productDetails?.loanDelinquencyData?.nonAccr?.minDue ??
                undefined,
            };
          }
          case "isAutoTrigger":
          case "isPaidToPrin":
          case "isReceivable": {
            return _booleanFieldUpdate(
              field,
              productDetails?.loanDelinquencyData?.nonAccr
            );
          }
          case "pastDueRestrictDays": {
            return {
              ...field,
              value:
                productDetails?.loanDelinquencyData?.pastDueRestrictDays ??
                undefined,
            };
          }
          case "pastDueTerms": {
            return {
              ...field,
              value: productDetails?.loanDelinquencyData?.pastDueTerms?.length
                ? productDetails?.loanDelinquencyData?.pastDueTerms
                    .map((item: any) => {
                      return item.term;
                    })
                    .join(", ")
                : undefined,
            };
          }
          case "noticeName": {
            return {
              ...field,
              value: productDetails?.loanDelinquencyData?.pastDueNotices?.length
                ? productDetails?.loanDelinquencyData?.pastDueNotices
                    .map((item: any) => {
                      return item.noticeName;
                    })
                    .join(", ")
                : undefined,
            };
          }
          case "pastDueDays": {
            return {
              ...field,
              value: productDetails?.loanDelinquencyData?.pastDueNotices?.length
                ? productDetails?.loanDelinquencyData?.pastDueNotices
                    .map((item: any) => {
                      return item.pastDueDays;
                    })
                    .join(`, `)
                : undefined,
            };
          }
          case "svcs": {
            return {
              ...field,
              value: productDetails?.svcs?.length
                ? productDetails?.svcs
                    .map((item: any) => {
                      return item.svcName;
                    })
                    .join(", ")
                : undefined,
            };
          }
          case "stmtFreq": {
            return {
              ...field,
              value: productDetails.stmtFreq
                ? productDetails.stmtFreq
                : productDetails.stmtFreqMatrix || undefined,
            };
          }
          default: {
            return _autoFieldUpdate(field, productDetails);
          }
        }
      });
      return { ...step, fields: updatedFields.filter(Boolean) };
    } else {
      return step;
    }
  });
  return { ...attribute, steps: updatedSteps };
};

const generateStructureForMultipleIteration = (
  featureFields: any,
  componentData: any,
  objectKey: string
) => {
  let result: any = {};

  componentData?.forEach((data: any, index: number) => {
    const key = `${objectKey} ${index + 1}`;
    let value: any = [];
    featureFields.forEach((field: any) => {
      let obj = {};
      switch (field.key) {
        case "trnCodeIncl":
        case "trnCodeExcl": {
          obj = {
            ...field,
            value: data[field.key]?.length
              ? data[field.key]?.join(", ")
              : undefined,
          };
          break;
        }
        case "maxCrAmt": {
          obj = {
            ...field,
            value: `$Min: ${data.minCrAmt} $Max: ${data.maxCrAmt}`,
          };
          break;
        }
        case "maxDrAmt": {
          obj = {
            ...field,
            value: `$Min: ${data.minDrAmt} $Max: ${data.maxDrAmt}`,
          };
        }
        case "startDtm": {
          obj = {
            ...field,
            value: formatTime(data.startDtm, "MMM DD, YYYY hh:mm:ssA"),
          };
          break;
        }
        case "voilationFeeButton": {
          obj = {
            ...field,
            value: data.violationFee ? "Yes" : undefined,
          };
          break;
        }
        default: {
          obj = {
            ...field,
            value: data[field.key] || undefined,
          };
        }
      }

      value.push(obj);
    });
    result[key] = value;
  });
  // If not items set
  if (!componentData?.length) {
    result[`No ${objectKey}s`] = [];
  }
  return result;
};

//Create sructure to display on product summary
const generateStructureForFee = (
  featureFields: any,
  componentData: any,
  objectKey: string
) => {
  let result: any = {};
  componentData?.forEach((data: any, index: number) => {
    const key = `${objectKey} ${index + 1}`;
    let value: any = [];
    featureFields.forEach((field: any) => {
      let obj = {};
      switch (field.key) {
        case "feeName": {
          obj = {
            ...field,
            value: data.feeName || undefined,
          };
          break;
        }
        case "feeTypeOpt": {
          obj = {
            ...field,
            value: data.feeTypeOpt || undefined,
          };
          break;
        }
        case "isAccum": {
          obj = _booleanFieldUpdate(field, data);
          break;
        }
        default: {
          obj = {
            ...field,
            value: data[field.key],
          };
        }
      }

      value.push(obj);
    });
    result[key] = value;
  });
  // If not items set
  if (!componentData?.length) {
    result[`No ${objectKey}s`] = [];
  }
  return result;
};

//Create sructure to display on product summary
const generateStructureForRules = (
  featureFields: any,
  componentData: any,
  objectKey: string
) => {
  let result: any = {};
  componentData?.forEach((data: any, index: number) => {
    const key = `${objectKey} ${index + 1}`;
    let value: any = [];
    featureFields.forEach((field: any) => {
      const dataVal = deepCopy(data[field.key]);
      let obj = {
        ...field,
        value: deepCopy(dataVal),
      };
      //building nested field values
      if (field?.subFields?.length && dataVal?.length) {
        let subObjArr: any[] = [];
        dataVal.forEach((item: any) => {
          field?.subFields.forEach((subItem: any) => {
            subObjArr.push({
              ...subItem,
              value: item?.[subItem.key] ?? "",
            });
          });
        });
        obj["subFields"] = subObjArr;
      }
      value.push(obj);
    });
    result[key] = value;
  });
  // If not items set
  if (!componentData?.length) {
    result[`No ${objectKey}`] = [];
  }
  return result;
};

const generateStructureForPastDueConfig = (
  featureFields: any,
  componentData: any,
  objectKey: string
) => {
  let result: any = {};
  componentData?.forEach((data: any, index: number) => {
    const key = `${objectKey} ${index + 1}`;
    let value: any = [];
    featureFields.forEach((field: any) => {
      let obj = {};
      switch (field.key) {
        case "isReceivable": {
          obj = _booleanFieldUpdate(field, data);
          break;
        }
        case "tolerancePct": {
          obj = {
            ...field,
            value: data[field.key] ? `${data[field.key]}%` : undefined,
          };
          break;
        }
        default: {
          obj = {
            ...field,
            value:
              data[field.key] !== undefined
                ? data[field.key] || undefined
                : undefined,
          };
        }
      }
      value.push(obj);
    });
    result[key] = value;
  });
  // If not items set
  if (!componentData?.length) {
    result[`No ${objectKey}s`] = [];
  }
  return result;
};

const getNewlyAddedComponent = (
  productData: string[],
  workflow: ProductWorkflowModelType[],
  productDetails: ProductType
) => {
  const workflowStages = workflow?.map((item) => item.stage);
  const savedComps = productDetails?.components?.map(
    (item) => item.componentClass
  );
  let savedCompWorkflowData: any[] = [];
  if (workflowStages?.length && savedComps?.length) {
    savedCompWorkflowData = savedComps
      .filter((componentClass) => !workflowStages.includes(componentClass))
      .map((stage) => {
        return {
          componentClass: stage,
          isCompleted: true,
        };
      });
  }

  const newComp =
    productData
      ?.filter(
        (component) => !workflow.some((item) => item.stage === component)
      )
      .map((stage) => {
        return {
          componentClass: stage,
          isCompleted: false,
        };
      }) || [];
  return [...savedCompWorkflowData, ...newComp];
};

const getRemovedComponent = (
  productData: string[],
  workflow: ProductWorkflowModelType[]
) => {
  return workflow.filter(
    (workflowItem) => !productData.some((item) => workflowItem.stage === item)
  );
};

function updateProductListStatus(
  productList: ProductType[],
  workflowProduct: ProductWorkflowModelType[]
) {
  let updatedProductList: ProductType[] = [...productList];

  updatedProductList = updatedProductList.map((product: ProductType) => {
    product.status = DASHBOARD_ACCORDION_STATUS.completed;
    product.components?.forEach(
      (item) => (item.status = DASHBOARD_ACCORDION_STATUS.completed)
    );

    const selectedProductFromWorkflow = workflowProduct?.filter(
      (prod) => prod?.modelKey === product?.name
    );
    if (!selectedProductFromWorkflow?.length) {
      return product;
    }

    product.components = product.components?.map((comp) => ({
      ...comp,
      status: getComponentStatusBasedOnWorkflowStatus(
        selectedProductFromWorkflow.find(
          (item) => item.stage === comp.componentClass
        )?.status ?? ""
      ),
    }));
    return {
      ...product,
      status: selectedProductFromWorkflow.some(
        (item) => item.status !== DASHBOARD_ACCORDION_STATUS.completed
      )
        ? DASHBOARD_ACCORDION_STATUS.inProgress
        : DASHBOARD_ACCORDION_STATUS.completed,
    };
  });
  return updatedProductList;
}

const getWorkFlowStageStatusToBeUpdated = (
  sideBarSubMenuList: any,
  sideBarSubMenuIndex: any,
  stageName: string,
  componentClass: string,
  workFlowData: ProductWorkflowModelType[]
) => {
  const currentWorkFlowComponent = workFlowData.find(
    (workFlowItem) => workFlowItem.stage === componentClass
  );

  const indexOfWorkFlowComponent =
    sideBarSubMenuList?.subMenuList &&
    sideBarSubMenuList.subMenuList.findIndex(
      (subMenuItem: any) =>
        subMenuItem.stageStatus === currentWorkFlowComponent?.status
    );

  return indexOfWorkFlowComponent && sideBarSubMenuIndex
    ? sideBarSubMenuIndex <= indexOfWorkFlowComponent
      ? currentWorkFlowComponent?.status
      : stageName
    : stageName;
};

const getComponentStatusBasedOnWorkflowStatus = (status: string) => {
  return status === DASHBOARD_ACCORDION_STATUS.notStarted
    ? DASHBOARD_ACCORDION_STATUS.notStarted
    : status !== DASHBOARD_ACCORDION_STATUS.completed
      ? DASHBOARD_ACCORDION_STATUS.inProgress
      : DASHBOARD_ACCORDION_STATUS.completed;
};

function getIfxProdType(productDetails: ProductType | null) {
  return productDetails?.ifxAcctType
    ? IFX_ACCT_PRODUCT_TYPE[productDetails?.ifxAcctType]
    : null;
}

function isProductTypeIsLoan(productDetails: ProductType | null) {
  return getIfxProdType(productDetails) === IFX_PRODUCT_TYPE_VAL.loan
    ? true
    : false;
}

export const shouldUpdateMatrix = (
  matrixComps: string[],
  compsToBeUpdated: string[]
) => {
  return (
    matrixComps?.length !== compsToBeUpdated.length ||
    !matrixComps?.every((matrix) =>
      compsToBeUpdated.some((comp) => comp === matrix)
    )
  );
};

export {
  defaultGroupInfo,
  setGroupInfoInStorage,
  getGroupInfoFromStorage,
  setQuickStartFlagInStorage,
  getQuickStartFlag,
  filterProductsWithWorkflowStatus,
  updateSideBarMenuStatus,
  getFeatureStatus,
  constructProductSummaryFeatureDetails,
  getNewlyAddedComponent,
  getRemovedComponent,
  updateProductListStatus,
  getWorkFlowStageStatusToBeUpdated,
  getIfxProdType,
  isProductTypeIsLoan,
  generateStructureForFee,
  generateStructureForRules,
};
