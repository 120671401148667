"use client";

import dayjs from "dayjs";
import {
  FrequencyModuleProps,
  frequencyModuleValue,
} from "@/components/common";
import {
  CRT_CONFIG_STAGE_MAPPING,
  DASHBOARD_ACCORDION_STATUS,
  GL_CONFIG_STAGE_MAPPING,
  IC_CONFIG_STAGE_MAPPING,
  IFX_PRODUCT_TYPE_VAL,
  IfxAcctProductType,
  MAIN_FORM_ID,
  PRODUCT_CLASS_NAME,
  PRODUCT_CLASS_NAME_WITH_COMPONENT,
  TC_STAGE_ORDER,
  TRANS_CODE_CONFIG_STAGE_MAPPING,
  RestrictedTags,
  OPTIONS_API_FIELD_FORMAT,
  CONSOLE_URLS,
  IFX_ACCT_PRODUCT_TYPE,
  ENTITLEMENT,
  ENTITLEMENT_STATUS,
  EntitlementType,
  OPTIONS_API_FIELD_TYPE,
  TRANSCODE_FLOW_NAME,
  DOC_READ_SPEED_WORDS_PER_MIN,
  TIME_TO_VIEW_IMAGE,
  IFX_ACCT_TYPE,
  FAST_SUPPORTED_IFX_ACCT_TYPES,
} from "./constants";
import moment from "moment";
import { FormEvent, useCallback, useMemo } from "react";
import { useSearchParams } from "next/navigation";
import { MutableRefObject } from "react";
import { DATE_FORMAT } from "../../../finxact-console/app/utils/constants";
import { useFormik } from "formik";
import { WorkflowResponseUnion } from "@/api-config/api-service";
import { PostSchema } from "@/components/product-management/model/types";
import RefParser from "@apidevtools/json-schema-ref-parser";
import { YupBuilder } from "schema-to-yup";
import _pick from "lodash-es/pick";
import { GENERIC_ERROR } from "@/components/data/error-data";
import { shallowEqual, useSelector } from "react-redux";
import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store";
import { QUERY_PARAM_KEY, ROUTE_PATH } from "@/route-config/route-path";
import styleVars from "@/styles/_export.module.scss";
import { CODE_BLOCK_SLICE_CLASSNAME } from "@/slices/CodeBlock";
import { REGEX_PATTERN } from "./regex-pattern";

const isBrowserPresent = () => typeof window !== "undefined"; //The approach recommended by Next.js

export function scrollToTop(topPosition = 0) {
  if (!isBrowserPresent()) return;
  window.scrollTo({ top: topPosition, behavior: "smooth" });
}

export function deepCopy<T>(data: T): T {
  return data ? JSON.parse(JSON.stringify(data)) : "";
}

const FREQUENCY_CYCLE_MAPPING: Record<string, string> = {
  M: "Month",
  D: "Day",
  B: "Business day",
  Y: "Year",
  E: "End of month",
};

const FREQUENCY_BUSINESS_DAY_MAPPING: Record<string, string> = {
  A: "Actual date",
  N: "Next business date",
  P: "Previous business date",
};

/**
 * @param input i.e 1D, 7WA, 1M31N etc
 * @returns converted value
 */
export const convertCodeToFrequency = (code: string): FrequencyModuleProps => {
  const matches = code?.match(/(\d+(\.\d+)?)([A-Za-z]?)([0-9E]*)([A-Za-z]?)/);
  return matches
    ? {
        intervalValue: parseFloat(matches[1]).toString() ?? "",
        cycleValue: FREQUENCY_CYCLE_MAPPING[matches[3].toUpperCase()] ?? "",
        dayOfMonth: matches[4] ?? "",
        notBusinessDay:
          FREQUENCY_BUSINESS_DAY_MAPPING[matches[5].toUpperCase()] ?? "",
      }
    : frequencyModuleValue;
};

// converts the duration code into object with number and dropdown values
export const convertCodeToDuration = (code: string) => {
  const matches = code?.match(/(\d+)([A-Za-z])/);
  return matches
    ? {
        inputValue: matches[1] ?? "",
        dropdownValue: FREQUENCY_CYCLE_MAPPING[matches[2].toUpperCase()] ?? "",
      }
    : {
        inputValue: "",
        dropdownValue: "",
      };
};

const _extractDate = (date: Date | null) => {
  return moment.parseZone(date).format(DATE_FORMAT);
};

// Splits DateTime string value
export const splitDateTime = (dateTime: string) => {
  const split = dateTime.split("T");
  return {
    date: new Date(split[0]),
    // Slicing away ending Z in time (2024-01-19T12:14:33.056Z)
    time: dateTime.includes("Z") ? split[1].slice(0, -1) : split[1],
  };
};

export const makeDateTime = (date = new Date(), time: string) => {
  return `${_extractDate(date)}T${time || "00:00:00"}.000Z`;
};

export const dateTimeFormat = (date: string) => {
  return date.includes("Z") ? date : `${date}.000Z`;
};
export const isValidDate = (date: Date) => {
  return date instanceof Date && date.toString() !== "Invalid Date";
};

export const formatDuration = (duration: number, shortLabel?: boolean) => {
  const durationInMinutes = duration / 60;
  const hours = Math.floor(durationInMinutes / 60);
  const minutes = Math.floor(durationInMinutes % 60);
  let hourLabel = shortLabel ? "hr" : "hours";
  let minLabel = shortLabel ? "min" : "minutes";

  return hours > 0
    ? `${hours} ${hourLabel} ${minutes} ${minLabel}`
    : `${minutes} ${minLabel}`;
};

export const formatDate = (dateString: string | number | Date) => {
  const date = new Date(dateString);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

export const formatDateDocCenter = (dateString: string | number | Date) => {
  const date = new Date(dateString);
  return `${String(date.getMonth() + 1).padStart(2, "0")}/${String(
    date.getDate()
  ).padStart(2, "0")}/${String(date.getFullYear()).slice(-2)}`;
};

export const formatStandardDate = (dateString: string | number | Date) => {
  const date = new Date(dateString);
  return dayjs(date).format("MMM D, YYYY");
};

export function getConstructedFeatureName(
  componentClass: string,
  prodType = IFX_PRODUCT_TYPE_VAL.deposit,
  isUpperCase: boolean = false
): string {
  const productType = prodType ? `(${prodType?.toLowerCase()})` : "";
  const featureName = PRODUCT_CLASS_NAME[componentClass]
    ? `${PRODUCT_CLASS_NAME[componentClass]} ${
        productType ? `(${prodType})` : ""
      }`
    : "";
  return isUpperCase ? featureName.toUpperCase() : featureName;
}
export function getConstructedFeatureNameWithComponent(
  componentClass: string,
  prodType: IfxAcctProductType,
  isUpperCase: boolean = false
): string {
  const productType = prodType ? `(${prodType?.toLowerCase()})` : "";
  const featureName = PRODUCT_CLASS_NAME_WITH_COMPONENT[componentClass]
    ? `${PRODUCT_CLASS_NAME_WITH_COMPONENT[componentClass]} ${productType}`
    : "";
  return isUpperCase ? featureName.toUpperCase() : featureName;
}

export const generateRegexToRestrictNoOfDigits = (
  minDigits: number,
  maxDigits: number
) => {
  return new RegExp(`^\\d{${minDigits},${maxDigits}}(?:\\.\\d+)?$`);
};

export const generateRegexToRestrictNoOfCharacters = (
  minChar: number,
  maxChar: number
) => {
  return new RegExp(`^[a-zA-Z0-9]{${minChar},${maxChar}}$`);
};

// To get number steps completed in specific journey in Dashboard
export const numberOfAccordionStepsCompleted = (data: any) => {
  if (data?.length > 0) {
    const completedSteps = data?.filter(
      (d: any) => d.status === DASHBOARD_ACCORDION_STATUS.completed
    );
    return completedSteps.length;
  } else {
    return 0;
  }
};

// To get number steps to be completed in Jumpstart activation in dashboard (if they are )
export const totalNumberOfStepsForJumpStart = (
  hasPlatformSetupAccess: boolean,
  hasAccessManagemnetAccess: boolean
) => {
  if (hasPlatformSetupAccess && hasAccessManagemnetAccess) {
    return 4;
  } else if (!hasPlatformSetupAccess && !hasAccessManagemnetAccess) {
    return 2;
  } else {
    return 3;
  }
};

//To show button on the accordion in dashboard
export const showButtonOnAccordion = (data: any, stage: string) => {
  return data?.length > 0
    ? data?.find((d: any) => d.stage === stage)?.status !==
      DASHBOARD_ACCORDION_STATUS.inProgress
      ? false
      : true
    : false;
};

export const showStepsStatus = (
  data: any,
  stage: string,
  hasAdminAccess: boolean = true
) => {
  if (!hasAdminAccess) {
    return DASHBOARD_ACCORDION_STATUS.notStarted;
  }
  return data?.length > 0
    ? data?.find((d: any) => d.stage === stage)?.status
    : DASHBOARD_ACCORDION_STATUS.notStarted;
};

export const formatTime = (time: Date, format: string) => {
  return time && format ? moment(time).format(format) : "";
};

export const specificFormattedTime = (time: Date, format: string) => {
  return time && format ? moment.utc(time).format(format) : "";
};

export function getCurrentDateAndTime() {
  var currentdate = new Date();
  var dateTime =
    currentdate.getDate() +
    " " +
    currentdate.toLocaleDateString("default", { month: "short" }) +
    " " +
    currentdate.getFullYear() +
    " " +
    currentdate.getHours() +
    ":" +
    currentdate.getMinutes() +
    ":" +
    currentdate.getSeconds();
  return dateTime;
}

// Hook to update the provided query param with the value
export const useQueryParams = () => {
  const searchParams = useSearchParams();
  const createQueryString = useCallback(
    (name?: string, value?: string) => {
      const params = new URLSearchParams(searchParams?.toString());
      if (name && value) {
        params.set(name, value);
      } else if (name) {
        // delete queryparam if no value provided
        params.delete(name);
      }

      return params.toString();
    },
    [searchParams]
  );
  return createQueryString;
};
export function scrollIntoView(ref: MutableRefObject<any>) {
  setTimeout(() => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
    });
  }, 500);
}

/* 
  Prevent top level form from submitting when submitting the RJSF form (id = rjsf_form).
  We are not able to prevent bubbling of the event to the parent using stopPropagation because of the way RJSF's onSubmit behaves
  Can't label it as a bug so opened a discussion https://github.com/rjsf-team/react-jsonschema-form/discussions/4104
*/
export const preventMainFormSubmitOnRJSFSubmit =
  (handleSubmit: ReturnType<typeof useFormik>["handleSubmit"]) =>
  (event: FormEvent<HTMLFormElement>) => {
    const { id } = event.target as HTMLFormElement;
    if (id === MAIN_FORM_ID) {
      handleSubmit(event);
    }
  };

// This is used in General ledger screens to get "General Ledger" account group
export const getGeneralLedgerAccountGroup = (
  data: { label: string; value: number }[],
  defaultValue: number
) => {
  if (!data?.length) {
    return defaultValue;
  }
  const object = data?.find((data: { label: string; value: number }) =>
    data.label.includes("General Ledger")
  );

  if (object) {
    return object.value;
  } else {
    return defaultValue;
  }
};

// Returns true if current step comes later than last completed step in workflow data
export const shouldUpdateWorkflowStatus = (
  workflowData: WorkflowResponseUnion[],
  currentStep: string
) => {
  if (workflowData?.length) {
    const stageKeys = Object.keys(IC_CONFIG_STAGE_MAPPING);
    const currentStageIndex = stageKeys.indexOf(currentStep);
    const lastWorkflowStage = workflowData[workflowData.length - 1];
    const lastWorkflowStageIndex = stageKeys.indexOf(lastWorkflowStage.status);
    return lastWorkflowStageIndex < currentStageIndex;
  }
  return true;
};

//URL updated from user
export const isIncorrectWorkflowStep = (
  workflowData: WorkflowResponseUnion[],
  currentStep: string,
  type: string
) => {
  let mapping: { [key: string]: string } = {};
  switch (type) {
    case "ic-config":
      mapping = IC_CONFIG_STAGE_MAPPING;
      break;
    case "gl-config":
      mapping = GL_CONFIG_STAGE_MAPPING;
      break;
    case "crt-config":
      mapping = CRT_CONFIG_STAGE_MAPPING;
      break;
    case "tc-config":
      mapping = TRANS_CODE_CONFIG_STAGE_MAPPING;
      break;
    default:
      console.error("Wrong url !");
      break;
  }
  if (workflowData?.length) {
    let lastWorkflowStage: WorkflowResponseUnion;
    let lastWorkflowStageIndex;
    const stageKeys = Object.keys(mapping);
    const currentStageIndex = stageKeys.indexOf(currentStep);
    if (type === "tc-config") {
      const temp = workflowData?.filter((data) => data.status === "completed");
      // When first 2 steps of TC are completed
      if (temp.length === 2) {
        lastWorkflowStage = workflowData.at(-1)!;
        // If TC Entry not started
        if (
          lastWorkflowStage.status === DASHBOARD_ACCORDION_STATUS.notStarted
        ) {
          lastWorkflowStageIndex = TC_STAGE_ORDER.tag_setup;
        } else {
          lastWorkflowStageIndex = stageKeys.indexOf(lastWorkflowStage.status);
        }
      } else {
        lastWorkflowStage = temp.at(-1)!;
        lastWorkflowStageIndex = stageKeys.indexOf(lastWorkflowStage.stage);
      }
    } else {
      lastWorkflowStage = workflowData.at(-1)!;
      lastWorkflowStageIndex = stageKeys.indexOf(lastWorkflowStage.status);
    }
    return currentStageIndex > lastWorkflowStageIndex + 1;
  }
  return false;
};

// Removes trailing slash
export const removeTrailingSlash = (path: string): string => {
  return path.replace(/\/+$/, "");
};

const _addNestedValidations = (schema: any, properties: any) => {
  for (const field in properties) {
    // Skip _fields
    if (field.startsWith("_")) {
      delete schema[field];
      continue;
    }
    if (properties[field].type === OPTIONS_API_FIELD_TYPE.object) {
      _addNestedValidations(schema[field].fields, properties[field].properties);
    }
    if (properties[field].type === OPTIONS_API_FIELD_TYPE.array) {
      _addNestedValidations(
        schema[field].innerType.fields,
        properties[field].items.properties
      );
    }
    // Add frequency validation to fields
    if (properties[field].format === OPTIONS_API_FIELD_FORMAT.frequency) {
      schema[field] = schema[field].matches(
        REGEX_PATTERN.FREQUENCY_INPUT,
        GENERIC_ERROR.frequencyPattern
      );
    }
    // Add duration validation to fields
    if (properties[field].format === OPTIONS_API_FIELD_FORMAT.duraiton) {
      schema[field] = schema[field].matches(
        REGEX_PATTERN.DURATION_INPUT,
        GENERIC_ERROR.durationError
      );
    }
    // Add uri validation
    if (properties[field].format === OPTIONS_API_FIELD_FORMAT.uri) {
      schema[field] = schema[field].matches(
        REGEX_PATTERN.URL_VALIDATION,
        GENERIC_ERROR.url
      );
    }
  }
};

/**
 * Generates Yup schema from OPTIONS API JSON schema
 */
export const generateYupSchema = async (
  fields: string[],
  apiSchema: PostSchema
) => {
  const { properties, required, ...otherSchema } = apiSchema;
  const dereferencedSchema = await RefParser.dereference({
    properties: {
      ..._pick(properties, fields),
    },
    required: required?.filter((field) => fields.includes(field)),
    ...otherSchema,
  });
  const deRefedProperties = dereferencedSchema.properties;
  const yupSchema = new YupBuilder(dereferencedSchema).propsToShape();

  // Automatically Add Duration/Frequency validations on root level fields
  _addNestedValidations(yupSchema, deRefedProperties);
  return yupSchema;
};

export const checkIfRestricted = function (documentTags: string[]) {
  // Check if any element of 'tags' exists in 'restrictedTags'
  if (documentTags?.length > 0) {
    return documentTags.some((tag) => RestrictedTags.includes(tag));
  }
  return false;
};

/**
 * Returns whether current user is permitted to specified operation ID.
 * @param value an operation ID (string), array of operation IDs (string[]), or matcher (regex)
 * @param inclusive meaning if any match in an array, return true.
 */
export function isPermittedCore(
  permissions: Set<string> | undefined,
  value?: string[] | string | RegExp,
  inclusive = true
) {
  if (!value || !permissions || permissions.size <= 0) {
    return false;
  }

  if (value instanceof RegExp) {
    for (const perm of permissions) {
      if (perm.match(value)) {
        return true;
      }
    }
  } else if (Array.isArray(value)) {
    // Inclusive = OR
    if (inclusive) {
      return value.some((item) => permissions.has(item));
    }
    // Not Inclusive = AND
    return value.every((item) => permissions.has(item));
  } else if (permissions.has(value)) {
    return true;
  }

  // No match
  return false;
}
/**
 * Returns whether current user is permitted to specified operation ID.
 * @param {*} value an operation ID (string), array of operation IDs (string[]), or matcher (regex)
 */
export function isPermittedConsole(
  permissions: Set<string> | undefined,
  value?: null | RegExp | string[] | string | Set<string>
) {
  if (!value || !permissions || permissions.size <= 0) {
    return false;
  }

  if (value instanceof RegExp) {
    for (const perm of permissions) {
      if (perm.match(value)) {
        return true;
      }
    }
  } else if (
    Array.isArray(value) ||
    value instanceof
      Set /* At some point, Set.intersection and Set.difference will stabilize. Until then... */
  ) {
    for (const item of value) {
      if (permissions.has(item)) {
        return true;
      }
    }
  } else if (permissions.has(value)) {
    return true;
  }

  // No match
  return false;
}

const selectSelf = (state: RootState) => state;
// Because this produces an object with nested object references (the `Set`s) that update every time this fires, we memoize it.
// This keeps unnecessary (and potentially harmful) rerenders from happening.
const selectPermissions = createDraftSafeSelector(
  selectSelf,
  (state: RootState) => ({
    primaryOrgCnsl: new Set(state.org.primaryOrgCnslPermissions),
    selectedOrgCnsl: new Set(state.org.consolePermissions),
    corePermissions: new Set(state.org.corePermissions),
  })
);

// permission based rendering
export function useIsPermitted() {
  const { corePermissions, selectedOrgCnsl, primaryOrgCnsl } = useSelector(
    selectPermissions,
    shallowEqual
  );

  return useCallback(
    (
      mode: "core" | "cnsl" | "primary-org-cnsl",
      value: string | RegExp | string[] | undefined, // operation
      inclusive?: boolean // only pertains to core mode
    ) => {
      if (mode === "core") {
        return isPermittedCore(corePermissions, value, inclusive);
      } else if (mode === "primary-org-cnsl") {
        return isPermittedConsole(primaryOrgCnsl, value);
      } else {
        return isPermittedConsole(selectedOrgCnsl, value);
      }
    },
    [selectedOrgCnsl, corePermissions, primaryOrgCnsl]
  );
}

// To check console pages or not
export const isConsolePages = () => {
  return CONSOLE_URLS.some((url) => window.location.href.includes(url));
};

// To check product type
export const useProductTypeCheck = (ifxType: string, prodType: string) => {
  return useMemo(() => {
    return !!(ifxType && IFX_ACCT_PRODUCT_TYPE[ifxType] === prodType);
  }, [ifxType, prodType]);
};

//Check entitlement
export const useUserEntitlement = () => {
  const isPermitted = useIsPermitted();

  const getEntitlementStatus = ({
    READ,
    CREATE,
    UPDATE,
    OPTIONS,
  }: {
    READ: string[];
    CREATE: string[];
    UPDATE: string[];
    OPTIONS: string[];
  }) => {
    const readAll = isPermitted("core", READ, false),
      createAll = isPermitted("core", CREATE, false),
      optionsAll = isPermitted("core", OPTIONS, false),
      updateAll = isPermitted("core", UPDATE, false);

    switch (true) {
      case !readAll || !optionsAll:
        return ENTITLEMENT_STATUS.noAccess;
      case createAll && updateAll:
        return ENTITLEMENT_STATUS.allAccess;
      case !createAll && updateAll:
        return ENTITLEMENT_STATUS.readUpdate;
      default:
        return ENTITLEMENT_STATUS.readOnly;
    }
  };

  const entitlementPerm = {
    ic: getEntitlementStatus(ENTITLEMENT.INSTITUTIONAL_CONFIGURATION),
    gl: getEntitlementStatus(ENTITLEMENT.GENERAL_LEDGER),
    tc: getEntitlementStatus(ENTITLEMENT.TRANSACTION_CODE),
    crt: getEntitlementStatus(ENTITLEMENT.CUSTOMER_RELATIONSHIP),
    pc_dep: getEntitlementStatus(ENTITLEMENT.PRODUCT_CONFIG_DEPOSIT),
    pc_loan: getEntitlementStatus(ENTITLEMENT.PRODUCT_CONFIG_LOAN),
  };
  return entitlementPerm;
};

export const checkReadOnlyEntitlement = (
  entitlement: EntitlementType,
  prodType?: "Deposit" | "Loan" | null
) => {
  const currentUrl = window.location.href;
  switch (true) {
    case currentUrl.includes("institutional-configuration") &&
      entitlement.ic === ENTITLEMENT_STATUS.readOnly:
    case currentUrl.includes("general-ledger") &&
      entitlement.gl === ENTITLEMENT_STATUS.readOnly:
    case currentUrl.includes("transaction-codes") &&
      entitlement.tc === ENTITLEMENT_STATUS.readOnly:
    case currentUrl.includes("customer-relationship-types") &&
      entitlement.crt === ENTITLEMENT_STATUS.readOnly:
    case (currentUrl.includes("product-configuration") ||
      currentUrl.includes("product-summary")) &&
      prodType === IFX_PRODUCT_TYPE_VAL.deposit &&
      entitlement.pc_dep === ENTITLEMENT_STATUS.readOnly:
    case (currentUrl.includes("product-configuration") ||
      currentUrl.includes("product-summary")) &&
      prodType === IFX_PRODUCT_TYPE_VAL.loan &&
      entitlement.pc_loan === ENTITLEMENT_STATUS.readOnly:
      return true;
    default:
      return false;
  }
};

export const checkEntitlement = (
  entitlement: EntitlementType,
  entitlementType: "ic" | "gl" | "tc" | "crt" | "pc_dep" | "pc_loan",
  entitlementStatus: string[],
  inclusive: boolean = false
) => {
  if (inclusive) {
    return entitlementStatus.reduce(
      (status, currentValue) =>
        entitlement[entitlementType] === currentValue && status,
      true
    );
  }
  return entitlementStatus.reduce(
    (status, currentValue) =>
      entitlement[entitlementType] === currentValue || status,
    false
  );
};
//convert incoming duel arrays to obj
export function convertArrToObj(a: any[], b: any[]) {
  if (a.length != b.length || a.length == 0 || b.length == 0) {
    return null;
  }

  // reduce it to obj
  let obj = a.reduce((acc, element, index) => {
    return {
      ...acc,
      [element]: b[index],
    };
  }, {});

  return obj;
}

export function findMatchingArrayIndex(
  arrayOfArrays: string[][],
  targetString: string
): number {
  // Iterate through the array of arrays
  for (let i = 0; i < arrayOfArrays.length; i++) {
    // Iterate through the elements of the current sub-array
    for (let j = 0; j < arrayOfArrays[i].length; j++) {
      // Check if the current element matches exactly with the target string
      if (arrayOfArrays[i][j] === targetString) {
        return i; // Return the index of the sub-array if an exact match is found
      }
    }
  }

  return -1; // Return -1 if no exact match is found
}

/**
 * Calculates the header grid row height for config pages,
 * if a prospect org is selected, it will account for the prospect banner below the page header
 * @param isProspect boolean: if org phase is prospect
 * @returns
 */
export const getConfigFormHeaderHeight = (isProspect: boolean) => {
  return isProspect
    ? `calc(${styleVars.configurationFormHeaderHeight} + ${styleVars.prospectBannerHeight})`
    : styleVars.configurationFormHeaderHeight;
};

export function constructTcConfigQueryParam(
  idType: "product" | "entity",
  id: string,
  flowName: TRANSCODE_FLOW_NAME
) {
  let idParam = "";
  switch (idType) {
    case "product":
      idParam = `${QUERY_PARAM_KEY["PRODUCT_NAME_KEY"]}=${id}`;
      break;
    case "entity":
      idParam = `${QUERY_PARAM_KEY["CONFIG_ID"]}=${id}`;
      break;
  }
  return `${idParam}&${ROUTE_PATH.FLOW_NAME_QUERY}${flowName}`;
}

export function removeSplChars(value: string) {
  return value.replace(/_/g, " ").toLowerCase();
}

export function nullCheckAndGetIndexVal(index: number | null | undefined) {
  return index !== null && index !== undefined && index >= 0 ? true : false;
}

export const computeDocReadTime = (element: HTMLDivElement) => {
  let allText = "";
  let child = element.firstChild as HTMLElement;
  const nestedImageCount =
    element.getElementsByClassName("prismic-image").length;
  while (child) {
    // Ignore any code block slices from the calculation of read time
    if (!child.className?.includes(CODE_BLOCK_SLICE_CLASSNAME)) {
      allText += child.innerText;
    }
    child = child?.nextSibling as HTMLElement;
  }
  // Get total number of words
  const words = allText.replace("\n", " ").split(" ").length;
  const time =
    words / DOC_READ_SPEED_WORDS_PER_MIN +
    nestedImageCount * TIME_TO_VIEW_IMAGE;
  // Calculate time in multiples of 2min if total time less than 10mins, else in multiple of 5mins
  return time < 10
    ? Math.ceil(time / 2) * 2
    : Number(time / 5) % 1 > 0.5 // if value is closer to higher multiple of 5
      ? Math.ceil(time / 5) * 5
      : Math.floor(time / 5) * 5;
};

/**
 * Get the current domain and if it is save (ends in finxact.io or is local
 *  console), return it.  Else, return finxact.io.
 * @returns
 */
export function safelyFetchDomain() {
  const d = window.location.host;
  if (d.endsWith("finxact.io") || d === "console.local:8080") {
    return d;
  } else {
    return "finxact.io";
  }
}

export const useEntityIdFromParam = () => {
  const params = useSearchParams();
  return params?.get(QUERY_PARAM_KEY.TC_ID) ?? "";
};

export const useFieldNameFromParam = () => {
  const params = useSearchParams();
  return params?.get(QUERY_PARAM_KEY.FIELD_NAME) ?? "";
};

export const useEntityId = () => {
  const params = useSearchParams();
  return params?.get("entityId")!;
};

// Returns if a product is supported by FAST
export const isFastProduct = (ifxAcctType: IFX_ACCT_TYPE) => {
  return FAST_SUPPORTED_IFX_ACCT_TYPES.includes(ifxAcctType);
};
