"use client";
import dynamic from "next/dynamic";
import { useState, FC, ReactNode, useEffect } from "react";
import "./OnboardingGuidedTooltip.scss";
import { Button } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon, CloseIcon } from "@chakra-ui/icons";

import { Progress } from "@chakra-ui/react";
import { usePathname, useRouter } from "next/navigation";
import { ROUTE_BASE_URL } from "@/route-config/route-path";
import { ACTIONS, LIFECYCLE } from "react-joyride";
import { UserInfo, useUserInfo } from "@/app/providers";
import { updateUserFastProfile } from "@/api-config/api-service";
import { removeTrailingSlash } from "@/utils/common";

const Joyride = dynamic(() => import("react-joyride"), { ssr: false });

type TooltipProps = {
  continuous: any;
  index: number;
  step: any;
  backProps: any;
  closeProps: any;
  primaryProps: any;
  tooltipProps: any;
  size: number;
};

type GuidedTooltipProps = {
  children: ReactNode;
  tooltipSteps: {
    content: string;
    title: string;
    placement: string;
    target: string;
    disableBeacon?: boolean;
  }[];
  onClickNext: (value: boolean) => void;
  onTooltipRender: (value: boolean) => void;
  resetTooltip: boolean;
  tooltipRenderstate: boolean;
};

const Tooltip: FC<TooltipProps> = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  size,
}) => (
  <div className="container">
    <div {...tooltipProps} className="guided-tooltip-container">
      <div>
        <div {...closeProps} className="close-icon-item">
          <span>{index + 1} of 5</span>
          <CloseIcon boxSize={5} />
        </div>
      </div>
      {step.title && <div className="title">{step.title}</div>}
      <div className="tooltip-container-content">{step.content}</div>

      <div className="button-wrapper">
        <Button
          {...backProps}
          className="back-button"
          leftIcon={
            index == 0 ? "" : <ChevronLeftIcon className="button-icon" />
          }
        >
          <div id="back">{step.leftButton}</div>
        </Button>
        {continuous && (
          <Button
            {...primaryProps}
            className="next-button"
            rightIcon={<ChevronRightIcon className="button-icon" />}
          >
            <div id="next">{step.rightButton}</div>
          </Button>
        )}
      </div>
    </div>
    <Progress
      value={index + 1}
      max={size}
      colorScheme="orange"
      color="gray"
      stroke="#454D56"
      className="progress-bar"
    />
  </div>
);

const OnboardingGuidedTooltip: FC<GuidedTooltipProps> = ({
  children,
  tooltipSteps,
  onClickNext,
  resetTooltip,
  onTooltipRender,
  tooltipRenderstate,
}) => {
  const [{ steps }, setState] = useState<any>({
    steps: tooltipSteps,
  });
  const routePathName = usePathname();
  const [stepIndex, setStepIndex] = useState(0);
  const [showTooltip, setShowTooltip] = useState(true);
  const { user, setUser } = useUserInfo();

  useEffect(() => {
    if (resetTooltip) {
      router.push(`${ROUTE_BASE_URL["DASHBOARD_PATH"]}`);
      onTooltipRender(true);
      setStepIndex(0);
    }
  }, [resetTooltip]);

  useEffect(() => {
    if (routePathName) {
      if (
        ROUTE_BASE_URL["DASHBOARD_PATH"] ==
          removeTrailingSlash(routePathName) &&
        stepIndex == 0
      ) {
        setShowTooltip(true);
        setStepIndex(0);
      }
      if (ROUTE_BASE_URL["ACADEMY"] == removeTrailingSlash(routePathName)) {
        setShowTooltip(true);
        setStepIndex(2);
      }

      if (ROUTE_BASE_URL["API_LIBRARY"] == removeTrailingSlash(routePathName)) {
        setStepIndex(4);
        setShowTooltip(true);
      }
      if (
        ROUTE_BASE_URL["DOCUMENT_CENTER"] == removeTrailingSlash(routePathName)
      ) {
        setShowTooltip(true);
        setStepIndex(3);
      }
      if (
        ROUTE_BASE_URL["DASHBOARD_PATH"] ==
          removeTrailingSlash(routePathName) &&
        stepIndex == 2
      ) {
        setShowTooltip(true);
        setStepIndex(1);
      }

      if (
        ROUTE_BASE_URL["DASHBOARD_PATH"] ==
          removeTrailingSlash(routePathName) &&
        stepIndex == 4
      ) {
        setShowTooltip(true);
        setStepIndex(0);
        onTooltipRender(false);
        if (!user.fastProfile.onboarding_guided_viewed) {
          updateUserFastProfileData();
        }
      }
    }
  }, [routePathName]);

  const router = useRouter();
  const recentlyViewedOnboardingGuided =
    user?.fastProfile?.onboarding_guided_viewed || true;
  const onboardingGuidedData = {
    ...user.fastProfile,
    onboarding_guided_viewed: recentlyViewedOnboardingGuided,
  };
  const updateUserFastProfileData = () => {
    const updateUser = () => {
      const updatedUserData: UserInfo = {
        ...user,
        fastProfile: {
          ...user.fastProfile,
          doc_center: {
            ...user.fastProfile?.doc_center,
          },
          onboarding_guided_viewed: recentlyViewedOnboardingGuided,
        },
      };
      setUser(updatedUserData);
    };
    updateUserFastProfile(onboardingGuidedData).then(() => {
      updateUser();
    });
  };
  const handleJoyrideCallback = (data: any) => {
    if (data.index == 0 && data.action == ACTIONS.NEXT) {
      onClickNext(true);
      setStepIndex(data.index + 1);
    }
    if (data.type === "step:after" && data.index === 1) {
      if (data.action === "next") {
        router.push(`${ROUTE_BASE_URL["ACADEMY"]}`);
        setShowTooltip(false);
        setStepIndex(2);
        onTooltipRender(true);
      }
    }
    if (data.type === "step:after" && data.index === 2) {
      if (data.action === "next") {
        router.push(`${ROUTE_BASE_URL["DOCUMENT_CENTER"]}`);
        setShowTooltip(false);
        setStepIndex(3);
        onTooltipRender(true);
      }
    }
    if (data.type === "step:after" && data.index === 3) {
      if (data.action === "next") {
        router.push(`${ROUTE_BASE_URL["API_LIBRARY"]}`);
        setShowTooltip(false);
        setStepIndex(4);
        onTooltipRender(true);
      }
    }
    if (data.type === "step:after" && data.index === 4) {
      if (data.action === "next") {
        router.push(`${ROUTE_BASE_URL["DASHBOARD_PATH"]}`);
        setShowTooltip(false);
        setStepIndex(0);
        onTooltipRender(false);
        onClickNext(false);
      }
    }
    if (
      data.index == 0 &&
      data.action == ACTIONS.PREV &&
      data.lifecycle == LIFECYCLE.COMPLETE
    ) {
      onTooltipRender(false);
    }
    if (
      data.index == 1 &&
      data.action == ACTIONS.PREV &&
      data.lifecycle == LIFECYCLE.COMPLETE
    ) {
      setStepIndex(data.index + (data.action === ACTIONS.PREV ? -1 : 1));
    }
    if (
      data.index == 2 &&
      data.action == ACTIONS.PREV &&
      data.lifecycle == LIFECYCLE.COMPLETE
    ) {
      router.push(`${ROUTE_BASE_URL["DASHBOARD_PATH"]}`);
      onClickNext(true);
    }
    if (
      data.index == 3 &&
      data.action == ACTIONS.PREV &&
      data.lifecycle == LIFECYCLE.COMPLETE
    ) {
      router.push(`${ROUTE_BASE_URL["ACADEMY"]}`);

      setShowTooltip(false);
    }
    if (
      data.index == 4 &&
      data.action == ACTIONS.PREV &&
      data.lifecycle == LIFECYCLE.COMPLETE
    ) {
      router.push(`${ROUTE_BASE_URL["DOCUMENT_CENTER"]}`);
    }
    if (data.action == ACTIONS.CLOSE) {
      onTooltipRender(false);
      setStepIndex(0);
      onClickNext(false);
      if (!user.fastProfile.onboarding_guided_viewed) {
        updateUserFastProfileData();
      }
      return;
    }
    if (data.action == ACTIONS.STOP) {
      onClickNext(false);
      if (!user.fastProfile.onboarding_guided_viewed) {
        updateUserFastProfileData();
      }
      setStepIndex(0);
      return;
    }
  };
  return (
    <>
      {showTooltip && (
        <Joyride
          continuous
          callback={handleJoyrideCallback}
          run={tooltipRenderstate}
          steps={steps}
          disableOverlay={stepIndex == 1 ? false : true}
          stepIndex={stepIndex}
          scrollToFirstStep
          showSkipButton
          showProgress={false}
          tooltipComponent={Tooltip}
          styles={{
            options: {
              arrowColor: "#26292E",
              zIndex: 1000,
            },
          }}
        />
      )}
      <div>{children}</div>
    </>
  );
};

export default OnboardingGuidedTooltip;
