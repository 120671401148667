import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { ROUTE_PATH } from "@/route-config/route-path";
import { Option } from "@/utils/types";

export interface OptionWithDesc extends Option<string> {
  desc: string;
  prodProgram? : string
}

export const generateViewUrl = (url: string, data: OptionWithDesc) => {
  switch (true) {
    case url.includes(ROUTE_PATH.TC_FOUNDATIONAL_DETAILS):
      return `${url}${ROUTE_PATH.ENTITY_ID_QUERY}${data.value}`;
    case url === API_ROUTE_CONFIGURATION.productProgramGroup:
      return `${url}/edit/${data.prodProgram}/${data.value}`;
    default:
      return `${url}/edit/${data.value}`;
  }
};
