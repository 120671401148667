"use client";

import {
  Box,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@/components/ChakraUiManager";
import React, { useContext } from "react";
import "./AppNumberInput.scss";
import { AppFormLabel } from "../..";
import { AppFormLabelProps } from "../form-label/AppFormLabel";
import { useSelector } from "react-redux";
import { getEntitlement, getEnvIsDisable } from "@/store";
import { checkReadOnlyEntitlement, isConsolePages } from "@/utils/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { IFX_ACCT_PRODUCT_TYPE } from "@/utils/constants";

type DefaultNumberInputProps = {
  min?: number;
  max?: number;
  isDisabled?: boolean;
  isReadOnly?: boolean;
};

interface AppNumberInputProps
  extends AppFormLabelProps,
    DefaultNumberInputProps {
  value: string | number;
  onChange: (value: string) => void;
  showInputStepper?: boolean;
  valuePrefix?: string;
  isDisabled?: boolean;
  placeholder?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  regex?: RegExp;
  label?: string;
  labelFor?: string;
  valueSuffix?: string;
  negativeValue?: boolean;
}

const AppNumberInput = (props: AppNumberInputProps) => {
  const {
    showInputStepper = false,
    valuePrefix = "",
    isRequired,
    labelName,
    tooltipDesc,
    value,
    isDisabled,
    placeholder,
    onChange,
    onBlur,
    regex,
    label,
    labelFor,
    valueSuffix = "",
    negativeValue = false,
  } = props;
  const { productDetails } = useContext<ProductConfigurationContextType>(
    productConfigurationContext
  );
  const envIsDisabled = useSelector(getEnvIsDisable);
  const entitlement = useSelector(getEntitlement);
  const isConsolePage = isConsolePages();
  const prodType = IFX_ACCT_PRODUCT_TYPE[productDetails?.ifxAcctType!];
  const checkEntitlement = checkReadOnlyEntitlement(entitlement, prodType);

  const formatValue = () => {
    if (valueSuffix !== "") {
      return `${value}${valueSuffix ?? ""}`;
    } else {
      return `${valuePrefix ?? ""}${value}`;
    }
  };
  const parse = (val = "") =>
    val && valuePrefix ? val.replace(new RegExp(`\\${valuePrefix}`), "") : val;

  const parseSuffix = (val = "") =>
    val && valueSuffix ? val.replace(new RegExp(`\\${valueSuffix}`), "") : val;

  const _handleChange = (valueString: string) => {
    if (regex) {
      // Check if the value is a valid input (allow numbers and alphabets, but restrict decimals)
      const isValidInput = regex.test(valueString);

      if (!isValidInput) {
        return; // Skip if restricting decimals and the input has a decimal
      }
    } else if (!negativeValue && !/^\d*?\.?\d*?$/.test(valueString)) {
      return; // Skip if not restricting decimals and the input is not a valid number
    }
    //Will not allow user to enter "e" or "E"
    if (valueString.includes("e") || valueString.includes("E")) {
      return;
    }
    if (valueSuffix !== "") {
      onChange(parseSuffix(valueString));
    } else {
      onChange(parse(valueString));
    }
  };

  const handleKeyDown = (e: any) => {
    if (
      e.key === "Backspace" &&
      valueSuffix !== "" &&
      e.target.selectionStart === value.toString().length + 1 &&
      e.target.selectionEnd === value.toString().length + 1
    ) {
      e.preventDefault();
      onChange(value.toString().slice(0, -1));
    } else if (e.key === "ArrowLeft") {
      const selectionStart = e.target.selectionStart;
      if (valuePrefix && selectionStart <= valuePrefix.length) {
        e.preventDefault();
      }
    }
  };

  const handleClick = (e: any) => {
    if (e.target.selectionStart < valuePrefix.length) {
      e.target.setSelectionRange(valuePrefix.length, valuePrefix.length);
    }
  };

  return (
    <Box className="app-number-input-wrapper">
      {labelName ? (
        <AppFormLabel
          isRequired={isRequired}
          tooltipDesc={tooltipDesc}
          labelName={labelName}
          labelFor={labelFor}
        />
      ) : (
        ""
      )}
      <NumberInput
        className="app-number-input-container"
        onChange={_handleChange}
        value={value ? formatValue() : ""}
        onBlur={onBlur}
        aria-label={label ? label : "Number input"}
        name={label ?? ""}
        onClick={handleClick}
        clampValueOnBlur={false}
        errorBorderColor="transparent"
        isDisabled={
          (envIsDisabled && isConsolePage) || checkEntitlement || isDisabled
        }
      >
        <NumberInputField placeholder={placeholder} onKeyDown={handleKeyDown} />
        {showInputStepper ? (
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        ) : (
          ""
        )}
      </NumberInput>
    </Box>
  );
};
export default AppNumberInput;
