"use client";
import css from "./ConfigurationStatusComponent.module.scss";

import { Flex, Divider, Box } from "@/components/ChakraUiManager";
import Image from "next/image";
import {
  checkIcon,
  checkWithCirle,
  lockIcon,
  rightRotate,
  checklistIcon,
} from "public/assets";
import { Badges } from "..";
import { ORG_PHASE, SUMMARY_LANDING_SCREEN_STATUS } from "@/utils/constants";
import { useMemo } from "react";
import { getSelectedOrg } from "@/store";
import { useSelector } from "react-redux";

interface ConfigurationStatusComponentProps {
  title: string;
  status: SUMMARY_LANDING_SCREEN_STATUS;
  leftBadgeLabel: string;
  leftBadgeStatus: SUMMARY_LANDING_SCREEN_STATUS;
  rightBadgeLabel: string;
  rightBadgeStatus: SUMMARY_LANDING_SCREEN_STATUS;
  justifyContent?: "center" | "flex-start";
}

const ConfigurationStatusComponent = (
  props: ConfigurationStatusComponentProps
) => {
  const {
    title,
    status,
    leftBadgeLabel,
    leftBadgeStatus,
    rightBadgeLabel,
    rightBadgeStatus,
  } = props;
  let leftBagdeType;
  let leftBadgeImage;
  const currOrg = useSelector(getSelectedOrg);
  switch (leftBadgeStatus) {
    case SUMMARY_LANDING_SCREEN_STATUS.completed:
      leftBagdeType = "success";
      leftBadgeImage = checkIcon;
      break;
    case SUMMARY_LANDING_SCREEN_STATUS.not_started:
      leftBagdeType = "default";
      leftBadgeImage = lockIcon;
      break;
    case SUMMARY_LANDING_SCREEN_STATUS.in_progress:
      leftBagdeType = "warning";
      leftBadgeImage = rightRotate;
      break;
  }

  let rightBagdeType;
  let rightBadgeImage;
  switch (rightBadgeStatus) {
    case SUMMARY_LANDING_SCREEN_STATUS.completed:
      rightBagdeType = "success";
      rightBadgeImage = checkWithCirle;
      break;
    case SUMMARY_LANDING_SCREEN_STATUS.not_started:
      rightBagdeType = "default";
      rightBadgeImage = lockIcon;
      break;
    case SUMMARY_LANDING_SCREEN_STATUS.in_progress:
      rightBagdeType = "warning";
      rightBadgeImage = rightRotate;
      break;
  }

  const mainIcon = useMemo(() => {
    switch (status) {
      case SUMMARY_LANDING_SCREEN_STATUS.unmet:
        return {
          icon: lockIcon,
          alt: "Unmet",
        };
      case SUMMARY_LANDING_SCREEN_STATUS.not_started:
        return {
          icon: rightRotate,
          alt: "ready to start",
        };
      case SUMMARY_LANDING_SCREEN_STATUS.in_progress:
        return {
          icon: rightRotate,
          alt: "In progress",
        };
      case SUMMARY_LANDING_SCREEN_STATUS.completed:
        return {
          icon: checklistIcon,
          alt: "Completed",
        };
      case SUMMARY_LANDING_SCREEN_STATUS.prod_completed:
        return {
          icon: checkWithCirle,
          alt: "Completed",
        };
    }
  }, [status]);

  return (
    <>
      {currOrg.phase === ORG_PHASE.implementation && (
        <div className={css["configuration-status-container"]}>
          <p
            className={`${css["configuration-status-title-text"]} ${
              status === SUMMARY_LANDING_SCREEN_STATUS.completed ||
              SUMMARY_LANDING_SCREEN_STATUS.prod_completed
                ? css["completed"]
                : ""
            }
        `}
          >
            {title}
          </p>
          <Flex className={css["top-style"]}>
            <Box justifySelf="flex-end">
              <Badges
                size="sm"
                label={leftBadgeLabel}
                type={leftBagdeType}
                image
                imageSrc={leftBadgeImage}
                imageAlt={leftBadgeLabel}
              />
            </Box>
            <Divider
              className={`top-line ${
                leftBadgeStatus === SUMMARY_LANDING_SCREEN_STATUS.completed
                  ? css["completed-line"]
                  : css["inprogress-line"]
              }`}
            />
            <Box className={`${css["status-icon"]} ${css[status]}`}>
              <Image src={mainIcon.icon} alt={mainIcon.alt} />
            </Box>
            <Divider
              className={`top-line ${
                rightBadgeStatus === SUMMARY_LANDING_SCREEN_STATUS.completed ||
                rightBadgeStatus === SUMMARY_LANDING_SCREEN_STATUS.in_progress
                  ? css["completed-line"]
                  : css["inprogress-line"]
              }`}
            />
            <Badges
              size="sm"
              label={rightBadgeLabel}
              type={rightBagdeType}
              image
              imageSrc={rightBadgeImage}
              imageAlt={rightBadgeLabel}
            />
          </Flex>
        </div>
      )}
    </>
  );
};

export default ConfigurationStatusComponent;
