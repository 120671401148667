import { ENV_ID_KEY, ENV_TYPE_KEY, EnvAllowConfigChanges } from "@/utils/constants";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import localforage from "localforage";
import { RootState } from "..";
import { Project } from "../types";

type ProjectState = {
  projId?: number,
  project?: Project,
  isLoading: boolean,
}

const initialProjState = {
  isLoading: false,
} as ProjectState;

// Load Env/Proj from browser storage
export const loadEnvOrProj = createAsyncThunk("proj/loadProject", async () => {
  try {
    const envId: number | null = await localforage.getItem(ENV_ID_KEY);
    const envType: "env" | "proj" | null = await localforage.getItem(ENV_TYPE_KEY);
    return {
      envId,
      envType,
    };
  } catch (err) {
    console.error(err);
  }
});

// Fetch project
export const fetchProject = createAsyncThunk(
  "proj/fetchProject",
  async ({ envId, envType }: { envId: number; envType: "env" | "proj" }) => {
    try {
      const proj =
        envType === "proj"
          ? await fetch(`/console/project/${envId}`).then((res) =>
              res.json()
            )
          : null;
      return proj
    } catch (err) {
      console.error(err);
    }
  }
);

// Create slice
const projectSlice = createSlice({
  name: "project",
  initialState: initialProjState,
  reducers: {
    setProj: (state, action: PayloadAction<Project | null>) => {
      state.project = action.payload ?? undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch proj
      .addCase(fetchProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProject.fulfilled, (state, action) => {
        const proj = action.payload || null;
        state.isLoading = false;
        state.project = proj;
      })
      // Load proj
      .addCase(loadEnvOrProj.pending, (state) => {
        state.isLoading = true
      })
      .addCase(loadEnvOrProj.fulfilled, (state, action) => {
        const { envId, envType } = action.payload || {};
        if (envType === 'proj') {
          state.projId = envId ?? undefined;
        } else {
          state.projId = undefined;
          state.project = undefined;
        }
        state.isLoading = false;
      });
  },
});

// Proj selectors
export const getProj = (state: RootState) => state.project.project;

export { projectSlice };
