"use client";

import React, {
  createContext,
  useMemo,
  useState,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useContext,
  useCallback,
  useEffect,
} from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@chakra-ui/react";
import { Box, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { MAIN_FORM_ID } from "@/utils/constants";
import Link from "next/link";
import { ROUTE_BASE_URL } from "@/route-config/route-path";
import css from "./UnsavedChanges.module.scss";

export interface IUnsavedChangesConfig {
  hasUnsavedChanges?: boolean;
  linkHref?: string;
  withoutSave?: boolean;
}

export interface IUnsavedChangesContext {
  unsavedChangesConfig: IUnsavedChangesConfig | undefined;
  setUnsavedChangesConfig: Dispatch<
    SetStateAction<IUnsavedChangesConfig | undefined>
  >;
  showModal: boolean;
  toggleModal: Dispatch<SetStateAction<boolean>>;
}

const UnsavedChangesContext = createContext<IUnsavedChangesContext | undefined>(
  undefined
);

const UnsavedChangesProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [unsavedChangesConfig, setUnsavedChangesConfig] = useState<
    IUnsavedChangesConfig | undefined
  >(undefined);
  const [showModal, toggleModal] = useState<boolean>(false);

  const context = useMemo(
    (): IUnsavedChangesContext => ({
      unsavedChangesConfig,
      setUnsavedChangesConfig,
      showModal,
      toggleModal,
    }),
    [unsavedChangesConfig, setUnsavedChangesConfig, showModal, toggleModal]
  );

  return (
    <UnsavedChangesContext.Provider value={context}>
      {children}
      <Modal
        closeOnOverlayClick={false}
        isOpen={showModal}
        onClose={() => {
          toggleModal(false);
          setUnsavedChangesConfig(undefined);
        }}
        isCentered={true}
      >
        <ModalOverlay className={css.overlay} />
        <Box className={css.content_container}>
          <ModalContent
            className={`app-modal-content ${css["unsaved-changes-modal-content"]}`}
          >
            <Box
              className={`app-modal-body-container ${css["unsaved-changes-modal-body-container"]}`}
            >
              <Box
                className={`app-modal-body ${css["unsaved-changes-modal-body"]}`}
              >
                <ModalHeader
                  className={`app-modal-title ${css["unsaved-changes-modal-title"]}`}
                >
                  You are attempting to exit the configuration without saving
                </ModalHeader>
                <ModalBody className={css["unsaved-changes-modal-text"]}>
                  Any progress that you have made on this step may be lost
                </ModalBody>
                <ModalFooter>
                  <Link
                    href={
                      unsavedChangesConfig?.linkHref ??
                      ROUTE_BASE_URL.DASHBOARD_PATH
                    }
                    onClick={() => {
                      toggleModal(false);
                      setUnsavedChangesConfig(undefined);
                    }}
                  >
                    <Button className="app-btn-link">
                      Exit without saving
                    </Button>
                  </Link>

                  {!unsavedChangesConfig?.withoutSave ? (
                    <Button
                      size="md"
                      className="app-btn-inverse-secondary"
                      onClick={() => {
                        toggleModal(false);
                      }}
                      type="submit"
                      form={MAIN_FORM_ID}
                    >
                      Save and exit
                    </Button>
                  ) : (
                    <Button
                      size="md"
                      className="app-btn-inverse-secondary"
                      onClick={() => {
                        toggleModal(false);
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                </ModalFooter>
              </Box>
            </Box>
          </ModalContent>
        </Box>
      </Modal>
    </UnsavedChangesContext.Provider>
  );
};

export default UnsavedChangesProvider;

export function useUnsavedChanges() {
  const context = useContext(UnsavedChangesContext);

  if (context === undefined) {
    throw new Error(
      "useSetUnsavedChanges must be called within <UnsavedChangesProvider />"
    );
  }

  const { unsavedChangesConfig, setUnsavedChangesConfig, toggleModal } =
    context;

  const setUnsavedChanges = useCallback(
    (config: Omit<IUnsavedChangesConfig, "linkHref">) => {
      setUnsavedChangesConfig(config);
    },
    [setUnsavedChangesConfig]
  );

  const clearUnsavedChanges = useCallback(() => {
    setUnsavedChangesConfig(undefined);
  }, [setUnsavedChangesConfig]);

  const showUnsavedChangesModal = useCallback(
    (proceedLinkHref: string) => {
      setUnsavedChangesConfig((currentContent) => ({
        ...currentContent,
        linkHref: proceedLinkHref,
      }));
      toggleModal(true);
    },
    [setUnsavedChangesConfig, toggleModal]
  );

  // Adding event listener to handle document unload unsaved progress modal
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (unsavedChangesConfig !== undefined) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [unsavedChangesConfig]);

  return useMemo(
    () => ({
      unsavedChangesConfig,
      setUnsavedChanges,
      clearUnsavedChanges,
      showUnsavedChangesModal,
    }),
    [
      unsavedChangesConfig,
      setUnsavedChanges,
      showUnsavedChangesModal,
      clearUnsavedChanges,
    ]
  );
}
