"use client";

import { Box, Text } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

import css from "./AddNewButton.module.scss";
import { useSelector } from "react-redux";
import { getEntitlement, getEnvIsDisable } from "@/store";
import noop from "lodash-es/noop";
import { IFX_ACCT_PRODUCT_TYPE } from "@/utils/constants";
import { useContext } from "react";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { checkReadOnlyEntitlement } from "@/utils/common";

type AddNewButtonProps = {
  label: string;
  className?: string;
  onClick: () => void;
};

const AddNewButton = (props: AddNewButtonProps) => {
  const { productDetails } = useContext<ProductConfigurationContextType>(
    productConfigurationContext
  );
  const { label, className, onClick } = props;
  const isEnvDisabled = useSelector(getEnvIsDisable);
  const prodType = IFX_ACCT_PRODUCT_TYPE[productDetails?.ifxAcctType!];
  const entitlement = useSelector(getEntitlement);
  const isReadOnly = checkReadOnlyEntitlement(entitlement, prodType);
  const shouldDisable = isReadOnly || isEnvDisabled;

  return (
    <Box
      className={`${css["add-new-button"]} ${shouldDisable ? css.disabled : ""} ${className}`}
      onClick={shouldDisable ? noop : onClick}
    >
      <AddIcon />
      <Text>{label}</Text>
    </Box>
  );
};

export default AddNewButton;
