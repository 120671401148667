"use client";
import {
  Input,
  Box,
  Textarea,
  InputGroup,
  InputRightElement,
  Icon,
} from "@/components/ChakraUiManager";
import "./InputText.scss";
import { useSelector } from "react-redux";
import { getEntitlement, getEnvIsDisable } from "@/store";
import { useContext, useState } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";

import { checkReadOnlyEntitlement, isConsolePages } from "@/utils/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import cx from "classnames";
import { IFX_ACCT_PRODUCT_TYPE } from "@/utils/constants";

interface InputTextProps {
  id?: string;
  name?: string;
  value?: string;
  className?: string;
  placeholder?: string;
  textarea?: boolean;
  onBlur?: (event: React.FocusEvent) => void;
  onChange?: (value: string) => void;
  alphaNumeric?: boolean;
  textWithUnderscore?: boolean;
  label?: string;
  regex?: RegExp;
  disabled?: boolean;
  phoneNumber?: boolean;
  password?: boolean;
  error?: boolean;
}
const InputText = (props: InputTextProps) => {
  const {
    id,
    value,
    onChange,
    className,
    placeholder,
    textarea,
    name,
    onBlur,
    label,
    regex,
    disabled,
    phoneNumber,
    password,
    error,
  } = props;
  const { productDetails } = useContext<ProductConfigurationContextType>(
    productConfigurationContext
  );
  const [showPassword, setShowPassword] = useState(!password);
  const entitlement = useSelector(getEntitlement);
  const envIsDisabled = useSelector(getEnvIsDisable);
  const isConsolePage = isConsolePages();
  const prodType = IFX_ACCT_PRODUCT_TYPE[productDetails?.ifxAcctType!];
  const checkEntitlement = checkReadOnlyEntitlement(entitlement, prodType);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event?.target.value;

    if (regex) {
      // Check if the value is a valid input
      const isValidInput = regex.test(value);

      if (!isValidInput) {
        return;
      }
    } else if (phoneNumber) {
      const input = event.target.value.replace(/\D/g, ""); // Remove non-digit characters
      let formattedNumber = "";

      // Add dash after every 3 digits
      for (let i = 0; i < input.length; i++) {
        if (i === 3 || i === 6) {
          formattedNumber += "-";
        }
        formattedNumber += input[i];
      }
      return onChange?.(formattedNumber.slice(0, 12));
    }
    onChange?.(value);
  };

  return (
    <Box className={`input-text-field-container ${className}`}>
      {textarea ? (
        <Textarea
          id={id}
          value={value}
          onChange={handleChange}
          focusBorderColor="none"
          placeholder={placeholder}
          rows={4}
          isDisabled={(envIsDisabled && isConsolePage) || checkEntitlement}
          aria-label={label ? label : "Text input"}
          className={cx({ error })}
        />
      ) : (
        <InputGroup>
          <Input
            id={id}
            isDisabled={
              (envIsDisabled && isConsolePage) || disabled || checkEntitlement
            }
            name={name}
            type={showPassword ? "text" : "password"}
            value={value}
            onChange={handleChange}
            focusBorderColor="none"
            onBlur={onBlur}
            placeholder={placeholder}
            aria-label={label ? label : "Text input"}
            className={cx({ "custom-input": true, error })}
          />
          {password && (
            <InputRightElement className="show-password-icon">
              <Icon
                cursor="pointer"
                boxSize={7}
                as={showPassword ? BsEye : BsEyeSlash}
                onClick={() => setShowPassword(!showPassword)}
              />
            </InputRightElement>
          )}
        </InputGroup>
      )}
    </Box>
  );
};

export default InputText;
