"use client";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Text,
  Heading,
} from "@/components/ChakraUiManager";

import Image from "next/image";
import { useRef, useEffect, useContext } from "react";
import "./AccordionComponent.scss";
import { StaticText } from "..";
import {
  accordionDeleteIcon,
  expandDownIcon,
  expandUpIcon,
} from "public/assets";
import { useSelector } from "react-redux";
import { getEnvIsDisable } from "@/store";
import { getEntitlement } from "@/store/slices/entitlement";
import {
  checkReadOnlyEntitlement,
  isConsolePages,
} from "@/utils/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { IFX_ACCT_PRODUCT_TYPE } from "@/utils/constants";

type AccordionComponentProp = {
  children: React.ReactNode;
  accordionTitle: string;
  limitName?: string | null;
  isExpand?: 0 | 1; // The value 0 will expand the accordion and if 0 is not given then 1 will be default value
  deleteIcon?: boolean;
  description?: string;
  onDeleteHandler?: () => void;
  maxTitleSize?: boolean;
  medTitleSize?: boolean;
};

const AccordionComponent = (props: AccordionComponentProp) => {
  const {
    children,
    accordionTitle,
    isExpand = 1,
    deleteIcon,
    onDeleteHandler = () => {},
    description,
    maxTitleSize = false,
    medTitleSize = false,
  } = props;
  const { productDetails } = useContext<ProductConfigurationContextType>(
    productConfigurationContext
  );
  const accordionRef = useRef<any>();
  const isEnvDisabled = useSelector(getEnvIsDisable);
  const entitlement = useSelector(getEntitlement);
  const prodType = IFX_ACCT_PRODUCT_TYPE[productDetails?.ifxAcctType!];
  const isReadOnly = checkReadOnlyEntitlement(entitlement, prodType);
  const isConsole = isConsolePages();
  const shouldDisable = isConsole && (isReadOnly || isEnvDisabled);

  useEffect(() => {
    accordionRef?.current.click();
  }, [isExpand]);

  const onClickDeleteIcon = (e: any) => {
    e.stopPropagation();
    onDeleteHandler();
  };

  return (
    <>
      <Accordion
        className="accordion-component"
        defaultIndex={[isExpand]}
        allowToggle
      >
        <AccordionItem className="accordion-item">
          {({ isExpanded }) => (
            <>
              <AccordionButton ref={accordionRef} className="accordion-button">
                <Box as="div" className="accordion-description-container">
                  <Box as="div" className="accordion-title-items">
                    <Box display="flex" as="div">
                      <Heading
                        as="h5"
                        className={
                          maxTitleSize || medTitleSize
                            ? maxTitleSize
                              ? "accordion-title-24"
                              : "accordion-title-20"
                            : "accordion-title-default"
                        }
                      >
                        {accordionTitle}
                      </Heading>
                      {deleteIcon && !shouldDisable && (
                        <Box className="accordion-delete-img">
                          <Image
                            onClick={onClickDeleteIcon}
                            src={accordionDeleteIcon}
                            alt="delete icon"
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box>
                    {description ? (
                      <StaticText
                        textValue={description || ""}
                        className="static-text-gray accordion-desc"
                      />
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
                {isExpanded ? (
                  <Image
                    className="accordion-expand"
                    src={expandUpIcon}
                    alt="expand up icon"
                  />
                ) : (
                  <Image
                    className="accordion-expand"
                    src={expandDownIcon}
                    alt="expand down icon"
                  />
                )}
              </AccordionButton>
              <AccordionPanel className="accordion-panel">
                {children}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </>
  );
};

export default AccordionComponent;
