import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EntitlementType } from "@/utils/constants";

const entitlementSlice = createSlice({
  name: "entitlement",
  initialState: {} as EntitlementType,
  reducers: {
    setEntitlement: (state, action: PayloadAction<EntitlementType>) => {
      return action.payload;
    },
  },
});

export const getEntitlement = (state: { entitlement: EntitlementType }) =>
  state.entitlement;

export { entitlementSlice };
