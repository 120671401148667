"use client";

import {
  AppFormLabel,
  AppModal,
  AppNumberInput,
  SelectDropdown,
  InputText,
} from "../..";
import {
  Box,
  Button,
  Flex,
  SmallCloseIcon,
  Tag,
  TagLabel,
  TagRightIcon,
  Text,
} from "@/components/ChakraUiManager";
import { AppFormLabelProps } from "../form-label/AppFormLabel";
import "./AppInputWithDropdown.scss";
import { useEffect, useMemo, useRef, useState, RefObject } from "react";
import { REGEX_PATTERN } from "@/utils/regex-pattern";
import { convertCodeToDuration } from "@/utils/common";

export interface AppInputWithDropdownProps extends AppFormLabelProps {
  value: string;
  onChange: (value: string) => void;
  dropdownList: string[];
  modalLinkName: string;
  modalTitle: string;
  modalLabelName: string;
  modalLabelTooltipDesc?: string;
  showDashedLeftBorder?: Boolean;
  regex?: RegExp;
  id?: string; //Set id only if there is no label for duration module to avoid ADA issue
  containerRef?: RefObject<HTMLElement | null> | undefined;
}

export default function AppInputWithDropdown(props: AppInputWithDropdownProps) {
  const {
    value,
    onChange,
    labelName = "",
    isRequired = false,
    tooltipDesc = "",
    dropdownList = [],
    modalLinkName = "",
    modalTitle = "",
    modalLabelName = "",
    modalLabelTooltipDesc = "",
    showDashedLeftBorder = true,
    regex,
    id,
    containerRef = undefined,
  } = props;

  const appInputModalRef = useRef<any>();
  const [codeInputSubmitted, setCodeInputSubmitted] = useState<string>("");
  const [inputValue, inputValueChange] = useState(
    convertCodeToDuration(value).inputValue
  );
  const [dropdownValue, dropdownChange] = useState(
    convertCodeToDuration(value).dropdownValue
  );

  const [codeEnteredManually, setCodeEnteredManually] = useState<string>("");

  const durationCode = useMemo(() => {
    return codeInputSubmitted
      ? codeInputSubmitted
      : `${inputValue}${dropdownValue ? dropdownValue[0] : ""}`;
  }, [inputValue, dropdownValue, codeInputSubmitted]);

  useEffect(() => {
    onChange(durationCode);
  }, [durationCode]);

  // If user enters code manually
  function onModalInputSave() {
    appInputModalRef.current.closeModal();
    // unset dropdown and input values
    setCodeInputSubmitted(codeEnteredManually);
    inputValueChange("");
    dropdownChange("");
  }

  // User clicks cancel on manual code entry
  function onModalInputCancel() {
    // Reset modal input value
    setCodeEnteredManually("");
  }

  function showModal() {
    appInputModalRef?.current?.openModal();
  }

  const clickedClear = () => {
    setCodeEnteredManually("");
    setCodeInputSubmitted("");
    onChange("");
  };

  const clickedEdit = () => {
    if (appInputModalRef?.current) {
      appInputModalRef.current.openModal();
      setCodeEnteredManually(codeInputSubmitted);
    }
  };

  /* 
    Assigning unique lables to input fields, 
    (when multiple Frequency module exist on the same page) 
  */
  const randomId = Math.random();

  return (
    <Box className={`app-form-field-container app-input-dropdown-container`}>
      {labelName === "" ? (
        ""
      ) : (
        <AppFormLabel
          labelName={labelName}
          isRequired={isRequired}
          tooltipDesc={tooltipDesc}
          labelFor={`${randomId}_appInputWithDropdown_input`}
        />
      )}

      {!codeInputSubmitted ? (
        <Flex
          direction={"column"}
          className={showDashedLeftBorder ? "dashed-left-border" : ""}
        >
          <Flex className={`app-input-dropdown-fields`}>
            <AppNumberInput
              value={inputValue}
              onChange={inputValueChange}
              regex={regex}
              placeholder="#"
            />
            <Box className="app-input-dropdown">
              <SelectDropdown
                dropdownList={dropdownList}
                value={dropdownValue}
                placeholder="Select"
                onChange={dropdownChange}
                id={id ? id : `${randomId}_appInputWithDropdown`}
              />
            </Box>
          </Flex>
          {/* Box to display userinput from input and select field */}
          {durationCode && (
            <Box className="app-input-dropdown-code-chip">
              <span>{durationCode}</span>
            </Box>
          )}

          <Text my="1.6rem">OR</Text>
          <Box>
            <Button onClick={showModal} className="app-btn-link">
              {modalLinkName}
            </Button>
          </Box>
        </Flex>
      ) : (
        <div className="app-input-dropdown-value-container">
          <Box>
            <Flex className="app-input-dropdown-selected-container">
              <>
                <Tag className="app-tags app-tag-selected" variant="solid">
                  <TagLabel textTransform="capitalize">
                    {codeInputSubmitted}
                  </TagLabel>
                  <TagRightIcon
                    className="outer"
                    as={SmallCloseIcon}
                    onClick={clickedClear}
                  />
                </Tag>
              </>
            </Flex>
            <Flex className="app-input-dropdown-edit-btn-group">
              <Button className="app-btn-link" onClick={clickedEdit}>
                Edit
              </Button>
              <Button className="app-btn-link" onClick={clickedClear}>
                Clear
              </Button>
            </Flex>
          </Box>
        </div>
      )}

      <AppModal
        customClass="app-input-with-dropdown-modal"
        ref={appInputModalRef}
        modalTitle={modalTitle}
        primaryBtnProp={{
          name: "Save",
          btnClassName: "app-btn-inverse-secondary",
        }}
        primaryBtnSelect={onModalInputSave}
        secondaryBtnSelect={onModalInputCancel}
        primaryBtnDisabled={codeEnteredManually === "" ? true : false}
        secondaryBtnProp={{
          name: "Cancel",
          btnClassName: "app-btn-reg-secondary",
          style: { marginRight: "1rem" },
        }}
        containerRef={containerRef}
      >
        <AppFormLabel
          labelName={modalLabelName}
          isRequired={true}
          tooltipDesc={modalLabelTooltipDesc}
        />
        <InputText
          value={codeEnteredManually}
          onChange={setCodeEnteredManually}
          regex={REGEX_PATTERN.ALPHA_NUMERIC}
        />
      </AppModal>
    </Box>
  );
}
