"use client";

import {
  Flex,
  Checkbox,
  TableContainer,
  CheckboxGroup,
  Box,
  Text,
  Button,
} from "@chakra-ui/react";
import { useContext, useMemo } from "react";
import CustomTable from "../custom-table/CustomTable";
import "./MultiSelectCheckBox.scss";
import Image from "next/image";
import { IconEdit } from "public/assets";
import AppFormLabel from "../form-fields/form-label/AppFormLabel";
import { useSelector } from "react-redux";
import { getEntitlement, getEnvIsDisable } from "@/store";
import { checkReadOnlyEntitlement, isConsolePages } from "@/utils/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { IFX_ACCT_PRODUCT_TYPE } from "@/utils/constants";

const MultiSelectCheckBox = ({
  data,
  selectedIds,
  setSelectedIds,
  keyName,
  columnDef,
  name,
  onEditButtonClick,
  footerComponent,
  tableLabel,
  isRequired,
  tooltipDesc,
  isDisabled = false,
}: {
  data: any[];
  selectedIds: any[];
  setSelectedIds: any;
  keyName: string;
  columnDef: any;
  name?: string;
  onEditButtonClick?: any;
  footerComponent?: React.ReactNode;
  tableLabel?: string;
  isRequired?: boolean;
  tooltipDesc?: string;
  isDisabled?: boolean;
}) => {
  const envIsDisabled = useSelector(getEnvIsDisable);
  const entitlement = useSelector(getEntitlement);
  const isConsolePage = isConsolePages();
  const { productDetails } = useContext<ProductConfigurationContextType>(
    productConfigurationContext
  );
  const prodType = IFX_ACCT_PRODUCT_TYPE[productDetails?.ifxAcctType!];
  const checkEntitlement = checkReadOnlyEntitlement(entitlement, prodType);

  const renderDataCell = (value: any, columnName: string, row?: any) => {
    if (columnName === "action") {
      return (
        <Flex direction={"row-reverse"}>
          <Checkbox
            id={row[keyName]}
            value={String(row[keyName])}
            isDisabled={
              (envIsDisabled && isConsolePage) || isDisabled || checkEntitlement
            }
          >
            <Text as="span" hidden>
              {row[keyName]}
            </Text>
          </Checkbox>
        </Flex>
      );
    } else if (columnName === "roles") {
      return row[columnName]?.map((item: any) => item.name)?.join(", ");
    } else if (columnName === "editAction") {
      return (
        <Button
          onClick={() => onEditButtonClick?.(row)}
          className="icon-button"
        >
          <Image src={IconEdit} alt="edit-icon" />
        </Button>
      );
    }
    return value;
  };
  const getAllIds = useMemo(() => {
    return data.map((item: any) => String(item[keyName]));
  }, [data, keyName]);
  const handleSelectAllClick = (e: any) => {
    let newArr: any[] = [];
    if (e.target.checked) {
      newArr = getAllIds;
    }
    setSelectedIds(newArr);
  };
  const isAllChecked =
    selectedIds?.length && getAllIds.length === selectedIds.length
      ? true
      : false;
  const renderHeaderCell = (value: any, headerDef: any) => {
    if (headerDef.id === "action") {
      return (
        <Flex
          className="custom-table-header-checkbox"
          direction={"row-reverse"}
        >
          <Checkbox isChecked={isAllChecked} onChange={handleSelectAllClick}>
            <Text as="span" hidden>
              {headerDef.id}
            </Text>
          </Checkbox>
        </Flex>
      );
    }
    return value;
  };

  const isRowSelected = (id: any) => {
    return selectedIds.some((item) => item == id[keyName]);
  };

  return (
    <Box className="multiselect-checkbox">
      <TableContainer className="roles-table-container" m={12}>
        {tableLabel && (
          <AppFormLabel
            labelName={tableLabel}
            isRequired={isRequired}
            tooltipDesc={tooltipDesc}
          />
        )}
        <CheckboxGroup
          defaultValue={selectedIds}
          value={selectedIds}
          onChange={(e: any) => {
            setSelectedIds(e);
          }}
          isDisabled={
            (envIsDisabled && isConsolePage) || isDisabled || checkEntitlement
          }
        >
          <CustomTable
            data={data}
            columns={columnDef}
            renderDataCell={renderDataCell}
            className="roles-table"
            renderHeaderCell={renderHeaderCell}
            isRowSelected={isRowSelected}
          />
        </CheckboxGroup>
        {footerComponent}
      </TableContainer>
    </Box>
  );
};

export default MultiSelectCheckBox;
